const ACR_RATIO_GOAL_GRAMS  = 30;
const ACR_RATIO_GOAL_MILLIMOLES  = 3.4;

const DEFAULT_SYSTOLIC_GOAL = 140; 
const DEFAULT_DIASTOLIC_GOAL = 90; 

const DEFAULT_HOME_SYSTOLIC_GOAL = 135;
const DEFAULT_HOME_DIASTOLIC_GOAL = 85;

const UNCONTROLLED_HYPERTENSION = "Uncontrolled hypertension";
const ELEVATED_BP = "Elevated blood pressure";
const MISSING_DATA = "Missing data";
const OK = "Ok";


export { ACR_RATIO_GOAL_GRAMS, ACR_RATIO_GOAL_MILLIMOLES,
  DEFAULT_DIASTOLIC_GOAL, DEFAULT_SYSTOLIC_GOAL, 
  DEFAULT_HOME_DIASTOLIC_GOAL, DEFAULT_HOME_SYSTOLIC_GOAL,
  UNCONTROLLED_HYPERTENSION, ELEVATED_BP, MISSING_DATA, OK };