import React, { useState, useEffect } from "react";
import BulkReportInput from './BulkReportInput'
import BPActivateReport from './BPActivateReport'
import BPACSV from './BPACSV'
import './UCSFBulkReportLauncher.scss';
import {analyzePatient} from './algorithm.js'
import { getFHIRData } from './FHIRApi.js'
import { searchUrlParams, clinicBPStats, patientFirstName, patientLastName, homeAddress, bpaDate} from './shared/utils.js'
import { DEFAULT_DIASTOLIC_GOAL, DEFAULT_SYSTOLIC_GOAL } from './shared/constants';
//import {patient, conditions, medications, measurements, allergies} from './data/sample_patient_1'
import {patient, conditions, medications, measurements, allergies} from './data/sample_patient_4'

//const LOCAL_AUTH='https://engagerx.ucsf.edu/api/authenticate?token=';
//const LOCAL_AUTH='https://engagerx.ucsf.edu/api/authenticate_local?patientMRN='
const LOCAL_AUTH='http://localhost:8081/api/authenticate_local?patientMRN='
//const LOCAL_AUTH='http://192.168.4.24:8081/api/authenticate_local?patientMRN='

const reportList = []
const csvLines = [[
    "mrn", "current_date", "first_name", "last_name","address_street","address_city", "address_state", "address_zip"," average_bp", "provider_name", "provider_department", "provider_address_street", "provider_address_city", "provider_address_state", "provider_address_zip", "insitution", "provider_phone", "next_appointment_date", "appointment_time", "yes_appointment", "no_appointment"
]]

export default function UCSFBulkReportLauncher ({env}) {

  const token= searchUrlParams('launch')
  const [totalPatients, setTotalPatients] = useState(0)
  //const [patientIndex, setPatientIndex] = useState(0)
  const [reports, setReports] = useState([])
  //const [csvLines, setCsvLines] = useState([])
  const [done, setDone] = useState(false)

  useEffect(() => {
    //setPatientIndex(reports.length)
    if (totalPatients > 0 && reportList.length === totalPatients ) {
      console.log("In useEffect, Got all the patients")
      setDone(true)
    }
  }, [reports, totalPatients]); 


  const authenticateDebug = async (mrn, env) => {
      //console.log(`In authenticate using this token: ${token}`)
      const url = `${LOCAL_AUTH}${mrn}&env=${env}`
      console.log(`sending to this url ${url}`)
  
      // fetch(url, {
      //   method: 'GET'
      // })
      // .catch(error => console.log('Error:', error))
      // .then(response => response.json())
      // .then((data) => {
      //   console.log("In authenticate got this data returned");
      //   console.log(data)
      //   return data;
      // });

      const response = await fetch(url, { method: 'GET' })
      const data = await response.json()
      console.log("In authenticate got this data returned");
      return data
  }

  const dataHandler = (patient, patientMRN, conditions, medications, measurements, allergies) => {
    console.log("BPA, patient is", patient)
    console.log("BPA, conditions are", conditions)
    console.log("BPA, medications are", medications)
    console.log("BPA, measurements are", measurements)
    console.log("BPA, allergies are", allergies)
    const recommendations = analyzePatient(
      DEFAULT_SYSTOLIC_GOAL,
      DEFAULT_DIASTOLIC_GOAL,
      patient, 
      conditions, 
      medications, 
      [],
      null,
      null,
      measurements.bpMeasurements,
      measurements.egfrMeasurements,
      measurements.lvefMeasurements,
      measurements.acrMeasurements,
      measurements.saltMeasurements,
      measurements.smokingStatus,
      allergies);
      const newReport = <BPActivateReport 
        patient={patient} 
        measurements={measurements} 
        recommendations={recommendations} 
      />
      addReport(newReport)
      addCsvLine(patientMRN, patient, measurements.bpMeasurements)
  }

  const addCsvLine = (patientMRN, patient, bpMeasurements) => {
    // mrn	current_date	first_name	last_name	address_street	address_city	address_state	address_zip	average_bp	provider_name	provider_department	provider_address_street	provider_address_city	provider_address_state	provider_address_zip	insitution	provider_phone	next_appointment_date	appointment_time	yes_appointment	no_appointment
    console.log("CSV, adding line to ", csvLines)
    //const tmpLines = [...csvLines]
    const address = homeAddress(patient)
    const clinicStats = clinicBPStats(bpMeasurements.slice(0, 2));
    csvLines.push([
      patientMRN, 
      bpaDate(),
      patientFirstName(patient.name),
      patientLastName(patient.name),
      address.line && address.line[0],
      address.city,
      address.state,
      address.postalCode,
      `${clinicStats.systolicMean}/${clinicStats.diastolicMean}`,
      '', // provider_name
      '', // provider_department
      '', // provider_address_street
      '', // provider_address_city
      '', // provider_address_state
      '', // provider_address_zip
      'UCSF', // institution
      '', // provider_phone
      '', // next_appointment_date
      '', // next_appointment_time
      '', // yes_appointment
      '' // no_appointment
    ])
    //setCsvLines(tmpLines);
    console.log("CSV, lines are now", csvLines)
  }

  const addReport = (report) => {
    const tmpReports = [...reports]
    tmpReports.push(report)
    setReports(tmpReports)
    reportList.push(report)
    console.log("BPA, reportList is", reportList)
  }

  const mrnHandler = async (mrns) => {
    //const env = "ace6"
    setTotalPatients(mrns.length)
    await mrns.forEach( async (mrn, index) => {
      console.log("Got ", mrn, index)

      // Real data
      const authData = await authenticateDebug(mrn, env)
      console.log("authData is", authData)
      getFHIRData(authData.patient, authData.access_token, env, null, null, dataHandler)

      // Harcoded data
      //dataHandler(patient, mrn, conditions, medications, measurements, allergies)
    });
    //setDone(true)
    console.log("Here is reportList", reportList)
  }

  const getReports = () => {
    return (
        reportList.map((report, index) => (
          <div className="report-with-pagebreak" key={index}>
            {report}
            <div className="pagebreak">&nbsp;</div>
          </div>
        ))
    )

  }

  return (
    <div className="UCSFBulkReportLauncher">
      { !done ?
        <BulkReportInput token={token} env={env} mrnHandler={mrnHandler} />
        :
        <div>
          <BPACSV data={csvLines} />
          {getReports()}
        </div>
      }
    </div>
  )
}
