import React from "react";
import Modal from 'react-modal';
import './Patient.scss';
import { FhirClientContext } from "./FhirClientContext";
import { Header } from "./Header";
import Recommendations from "./Recommendations";
import { Rationale } from "./Rationale";
import { Expander } from "./Expander";
import { Medications } from "./Medications";
import { Labs } from "./Labs";
import BP from "./BP";
import {analyzePatient} from './algorithm.js'
import { getFHIRData } from './FHIRApi.js'
import { SubmitFeedback } from './SubmitFeedback'
import { searchUrlParams, patientFirstName, patientLastName, age, 
  phoneNumber, sortMeasurements, logUsage, submitFeedback,
  getStorageKey } from './shared/utils.js'
import { DEFAULT_DIASTOLIC_GOAL, DEFAULT_SYSTOLIC_GOAL } from './shared/constants';

export function getBrowserVisibilityProp() {
  if (typeof document.hidden !== "undefined") {
    // Opera 12.10 and Firefox 18 and later support
    return "visibilitychange"
  } else if (typeof document.msHidden !== "undefined") {
    return "msvisibilitychange"
  } else if (typeof document.webkitHidden !== "undefined") {
    return "webkitvisibilitychange"
  }
}

export function getBrowserDocumentHiddenProp() {
  if (typeof document.hidden !== "undefined") {
    return "hidden"
  } else if (typeof document.msHidden !== "undefined") {
    return "msHidden"
  } else if (typeof document.webkitHidden !== "undefined") {
    return "webkitHidden"
  }
}

export function getIsDocumentVisible() {
  return !document[getBrowserDocumentHiddenProp()]
}

const ModalStyle = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    paddingTop: '20px',
    paddingBottom: '20px',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};


export default class Patient extends React.Component {

  static contextType = FhirClientContext;

  constructor(props) {
    super(props);
    this.patientId = null;
    this.userId = null;
    const user_str = searchUrlParams('user')
    if (user_str) {
      this.userId = user_str;
    }

    const id_str = searchUrlParams('id')
    if (id_str) {
      this.patientId = id_str;
    }

    console.log(`PatientID is: ${this.patientId}`)
    this.state = {
      visible: true,
      loading: true,
      patient: null,
      patientMRN: null,
      showMoreLabs: false,
      showMoreBps: false,
      medications: null,
      allergies: null,
      conditions: null,
      observations: null,
      bpMeasurements: null,
      homeBpMeasurements: null,
      homeBp_average: null,
      acrMeasurements: null,
      egfrMeasurements: null,
      lvefMeasurements: null,
      saltMeasurements: null,
      labMeasurements: null,
      smokingStatus: null,
      recommendations: null,
      homeBpError: null,
      feedbackText: "",
      clinicGoalSBP: DEFAULT_SYSTOLIC_GOAL,
      clinicGoalDBP: DEFAULT_DIASTOLIC_GOAL,
      homeGoalSBP: null,
      homeGoalDBP: null,
      showMoreOptions: {}
    };

    this.queryMeds = null;
    const queryMeds_str = searchUrlParams('medications')
    if (queryMeds_str) {
      this.queryMeds = queryMeds_str.split('|') 
    }

    this.query_tabs = null;
    const query_tabs_str = searchUrlParams('tabs')
    if (query_tabs_str) {
      this.query_tabs = Number(query_tabs_str)
    }

    this.queryConds = null;
    const queryConds_str = searchUrlParams('conditions')
    if (queryConds_str) {
      this.queryConds = queryConds_str.split('|') 
    }

    this.querySbp = null;
    const querySbp_str = searchUrlParams('sbp')
    if (querySbp_str) {
      this.querySbp = querySbp_str.split(',') 
    }

    this.queryDbp = null;
    const queryDbp_str = searchUrlParams('dbp')
    if (queryDbp_str) {
      this.queryDbp = queryDbp_str.split(',') 
    }

    this.queryEgfr = null;
    const queryEgfrStr = searchUrlParams('egfr')
    if (queryEgfrStr) {
      this.queryEgfr = queryEgfrStr.split('|') 
    }

    this.queryAllergies = null;
    const queryAllergyStr = searchUrlParams('allergy')
    if (queryAllergyStr) {
      this.queryAllergies = queryAllergyStr.split('|') 
    }
  }

  
  onVisibilityChange = () => {
    const currentlyVisible = getIsDocumentVisible();
    if (currentlyVisible && this.state.visible === false) {
      this.handleRefresh();
    }
    this.setState({visible: currentlyVisible})
  }

  updateBP = (homeBpMeasurements, averageSbp, averageDbp, newGoalSbp=null, newGoalDbp=null) => {
    console.log("In updateBP, homeBPMeasurements are: ", homeBpMeasurements)
    const recommendations = analyzePatient(
      newGoalSbp,
      newGoalDbp,
      this.state.patient, 
      this.state.conditions, 
      this.state.medications, 
      homeBpMeasurements,
      averageSbp,
      averageDbp,
      this.state.bpMeasurements,
      this.state.egfrMeasurements,
      this.state.lvefMeasurements,
      this.state.acrMeasurements,
      this.state.saltMeasurements,
      this.state.smokingStatus,
      this.state.allergies);
    console.log("Re-analyzed patient. Recos now are:");
    console.log(recommendations);
    this.setState({ recommendations, homeBpMeasurements, averageSbp, averageDbp });
  }

  updatePatient = (patient, patientMRN, conditions, medications, measurements,
    allergies, goal) => {
    logUsage(this.props.env, this.userId, patientMRN, this.props.encounter);

    const sbp_goal = goal?.sbp_goal || this.state.clinicGoalSBP
    const dbp_goal = goal?.dbp_goal || this.state.clinicGoalDBP

    const homeBpMeasurements = sortMeasurements(measurements.homeBpMeasurements);
    const start = new Date()
    const recommendations = analyzePatient(
      sbp_goal,
      dbp_goal,
      patient, 
      conditions, 
      medications, 
      homeBpMeasurements,
      null,
      null,
      measurements.bpMeasurements,
      measurements.egfrMeasurements,
      measurements.lvefMeasurements,
      measurements.acrMeasurements,
      measurements.saltMeasurements,
      measurements.smokingStatus,
      allergies);

    console.log(`Analyzing Patient took: ${new Date() - start}`);
    this.setState({ 
      patient, 
      patientMRN,
      conditions,
      medications,
      homeBpMeasurements: homeBpMeasurements,
      bpMeasurements: measurements.bpMeasurements,
      egfrMeasurements: measurements.egfrMeasurements,
      acrMeasurements: measurements.acrMeasurements,
      lvefMeasurements: measurements.lvefMeasurements,
      saltMeasurements: measurements.saltMeasurements,
      labMeasurements: measurements.labMeasurements,
      smokingStatus: measurements.smokingStatus,
      showMoreOptions: {},
      clinicGoalSBP: sbp_goal,
      clinicGoalDBP: dbp_goal,
      recommendations,
      allergies,
      loading: false
    });
  }

  componentDidMount() {
    const client = this.context.client;
    if (!this.patientId) {
      this.patientId = this.props.patientId || client.patient.id;
      const sbp = parseInt(sessionStorage.getItem(getStorageKey(this.patientId, this.userId, "sbp"))) || this.state.clinicGoalSBP;
      const dbp = parseInt(sessionStorage.getItem(getStorageKey(this.patientId, this.userId, "dbp"))) || this.state.clinicGoalDBP;
      this.setState({clinicGoalSBP: sbp, clinicGoalDBP: dbp})
    }
    console.log(`Now this.patient id is: ${this.patientId}`);

    const visibilityChange = getBrowserVisibilityProp()
    document.addEventListener(visibilityChange, this.onVisibilityChange, false)

    this.handleRefresh();
  }

  componentWillUnmount() {
    const visibilityChange = getBrowserVisibilityProp()
    document.removeEventListener(visibilityChange, this.onVisibilityChange)
  }

  handleBPGoal = (sbp, dbp) => {
    this.setState({clinicGoalSBP: sbp, clinicGoalDBP: dbp})
    sessionStorage.setItem(getStorageKey(this.patientId, this.userId, "sbp"), sbp)
    sessionStorage.setItem(getStorageKey(this.patientId, this.userId, "dbp"), dbp)
    this.updateBP(this.state.homeBpMeasurements, null, null, sbp, dbp);
  }

  handleRefresh = () => {
    this.setState({loading: true})
    getFHIRData(this.patientId, this.props.accessToken, this.props.env, this.props.encounter, 
      this.context.client, this.updatePatient, this.querySbp, this.queryDbp, 
      this.queryAllergies, this.queryMeds, this.query_tabs, this.queryConds, this.queryEgr)
  }

  handleShowMoreLabsClick = () => {
    const currValue = this.state.showMoreLabs;
    this.setState({showMoreLabs: !currValue})
  }

  handleShowMoreBpsClick = () => {
    const currValue = this.state.showMoreBps;
    this.setState({showMoreBps: !currValue})
  }

  handleOptionClick = (recoId) => {
    const { showMoreOptions } = this.state;
    if (showMoreOptions.hasOwnProperty(recoId)) {
      showMoreOptions[recoId] = !showMoreOptions[recoId]
    } else {
      showMoreOptions[recoId] = true;
    }
    this.setState({showMoreOptions})
  }

  handleCloseSubmitFeedback = () => {
    submitFeedback(this.props.env, this.userId, this.state.patientMRN, this.props.encounter, this.state.feedbackText);
    this.setState({feedbackModalIsOpen: false, feedbackText: ""})
  }

  setFeedbackText = (event) => {
    console.log("Gonna set feedback text to", event.target.value)
    this.setState({feedbackText: event.target.value})
  }

  render() {
    const { loading, 
            patient, 
            labMeasurements,
            bpMeasurements, 
            averageSbp,
            averageDbp,
            homeBpMeasurements, 
            recommendations,
            conditions,
            clinicGoalSBP,
            clinicGoalDBP
          } = this.state;

    if (loading) {
      return (
        <div id="Patient">
          <h2 className="loading">Loading data...</h2>
        </div>
      );
    }

    //console.log("MIKE, IN RENDER, homeBPMeasurments are", homeBpMeasurements);
    console.log("MIKE, IN RENDER, goals are", clinicGoalSBP, clinicGoalDBP)

    return (
      <div id="Patient">
        <Header userName="Dr. Valy Fontil" 
                patientFirstName={patientFirstName(patient.name)} 
                patientLastName={patientLastName(patient.name)}
                dob={patient.birthDate}
                handleRefresh={this.handleRefresh}
                handleSubmitFeedback={() => this.setState({feedbackModalIsOpen: true})}
                age={age(patient)}
                mrn={this.state.patientMRN} />

        <div className="columns">
          <div className="column left">
            <div className="recommendations">
              <Recommendations 
                conditions={conditions} 
                age={age(patient)} 
                gender={patient.gender} 
                recommendations={recommendations} 
                userName="Dr. Valy Fontil" 
                phone={phoneNumber(patient)}
                userId={this.userId} 
                patientMRN={this.state.patientMRN} 
                encounter={this.props.encounter} 
                env={this.props.env}
                handleBPGoal={this.handleBPGoal}
              />
            </div>
            <div className="rationale">
              <Expander title="Rationale for Recommendations">
                <Rationale reasons={recommendations.reasons}/>
              </Expander>
            </div>
          </div>
          <div className="column">
            <div className="medications">
              <Expander title="Medications">
                <Medications medications={recommendations.medications} otherMedications={recommendations.otherMedications}/>
              </Expander>
            </div>
            <div className="labs">
              <Expander title="Lab Results">
                <Labs measurements={labMeasurements}/>
              </Expander>
            <div className="bp">
              <Expander title="BP Measurements">
                <BP 
                  measurements={bpMeasurements} 
                  homeMeasurements={homeBpMeasurements} 
                  averageSbp={averageSbp} 
                  averageDbp={averageDbp} 
                  updateBP={this.updateBP} 
                  clinicGoalSBP={clinicGoalSBP}
                  clinicGoalDBP={clinicGoalDBP}
                />
              </Expander>
            </div>
            </div>
          </div>
        </div>
        <Modal
          isOpen={this.state.feedbackModalIsOpen}
          style={ModalStyle}
        >
          <SubmitFeedback
            feedbackText={this.state.feedbackText}
            setFeedbackText={this.setFeedbackText}
            handleSubmitFeedback={this.handleCloseSubmitFeedback}
            handleCancelFeedback={() => this.setState({feedbackModalIsOpen: false})}>
          </SubmitFeedback>
        </Modal>
      </div>
    );
  }
}
