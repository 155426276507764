import React from "react";
import './Documentation.scss';
import {formatConditions } from './shared/utils.js'
import { UNCONTROLLED_HYPERTENSION, ELEVATED_BP } from './shared/constants.js'

export function Documentation(props) {
  var text = null;
  if (props.diagnosis === UNCONTROLLED_HYPERTENSION) {
    var med = null;
    if (props.medication.amount && props.medication.unit)
      med = `${props.medication.name}, ${props.medication.amount} ${props.medication.unit}`
    else
      med = props.medication.name || '';
    text = `${props.age} year old ${props.gender} Patient with a history of ${formatConditions(props.conditions)} who presents with currently uncontrolled hypertension. For treatment of uncontrolled hypertension: ${props.verb || ''} ${props.hint || med}`
  } else if (props.diagnosis === ELEVATED_BP) {
    text = `${props.age} year old ${props.gender} Patient with a history of ${formatConditions(props.conditions)} who presents with currently elevated blood pressure. Engage-Rx recommends checking home BP and/or reassess clinic BP in 2 weeks.`
  }

  return (
    <div id="Documentation">
      <div className="contents">
        <p>{text}</p>
      </div>
    </div>
  );
}