import React from "react";
import ReactTooltip from 'react-tooltip';
import { Checkbox } from "./Checkbox";
import { Documentation } from "./Documentation";
import { Expander } from "./Expander";
import { BPGoal } from "./BPGoal";
import './Recommendations.scss';
import { logUsage, logRecommendations, getHomeDPB, getHomeSPB } from './shared/utils.js'
import { OK, UNCONTROLLED_HYPERTENSION } from "./shared/constants";

const ENROLL_API='https://engagerx.ucsf.edu/api/enroll_patient';
//const ENROLL_API='http://localhost:8081/api/enroll_patient';

const AGL_BMP_KEY = "BMP"

export default class Recommendations extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      showDocumentation: false,
      phone: this.props.phone,
      chosenMedicationIsTopRecommendation: false,
      chosenMedication: "",
      chosenMedicationVerb: "",
      chosenMedicationAglKey: "",
      basicMetabolicPanelNeeded: false,
      hintOverride: "",
      showRecommendations: false, 
      showBPGoal: false,
      showDietInstructions: false,
      showHomeBPInstructions: false,
      showHomeMonitoring: false,
      showOtherOptions: false
    }
  }

  componentDidMount = () => {
    // Tooltips don't work for content that was loaded during a rerender without rebuilding
    //this.setState({tooltipIsVisble: this.areTooltipsVisible(this.props.recommendations)})
    ReactTooltip.rebuild();
    this.onLoad();
    if (this.props.recommendations)
      logRecommendations(this.props.env, this.props.userId, this.props.patientMRN, this.props.encounter, this.props.recommendations);
  }

  agreeAndOrder = () => {
    // Disconnected this for now.
    //if (this.state.phone && this.state.showHomeMonitoring) {
    if (false) {
      console.log("Gonna call /enroll_patient")
      fetch(ENROLL_API, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          env: this.props.env,
          patientMRN: this.props.patientMRN, 
          phone: this.state.phone}) // body data type must match "Content-Type" header
      })
      .catch(error => console.error('Error:', error))
      .then(response => {
        return response.json()
      })
      .then((data) => {
        console.log("Got this reponse", data)
        alert("Patient is enrolled for home BP monitoring")
      });
    } else {
      this.logOrderEvents();
      var aglkey = this.state.chosenMedicationAglKey;
      if (aglkey) {
        console.log("Will place order for", aglkey);
        window.parent.postMessage({
          token: this.epicToken,
          action: "Epic.Clinical.Informatics.Web.PostOrder",
          args: { OrderKey: aglkey }
        }, "*");
        //alert(`Order placed for ${aglkey}`);
      } 
      if (this.state.basicMetabolicPanelNeeded) {
        console.log("Will place order for BMP");
        window.parent.postMessage({
          token: this.epicToken,
          action: "Epic.Clinical.Informatics.Web.PostOrder",
          args: { OrderKey: AGL_BMP_KEY }
        }, "*");
        //alert(`Order placed for ${AGL_BMP_KEY}`);
      }
    }
  }

  logOrderEvents = () => {
    if (this.state.basicMetabolicPanelNeeded) {
      logUsage(this.props.env, this.props.userId, this.props.patientMRN, this.props.encounter, "MetabolicPanelOrdered");
    }
    if (this.state.chosenMedicationIsTopRecommendation) {
      logUsage(this.props.env, this.props.userId, this.props.patientMRN, this.props.encounter, "TopRecommendationOrdered");
    } else if (this.state.chosenMedicationAglKey) {
      logUsage(this.props.env, this.props.userId, this.props.patientMRN, this.props.encounter, "RecommendationOrdered");
    }

  }

  formatOptions = (options, verb, reco, prev_option_num, recoIndex) => {
    //console.log("Mike in format options with reco", reco)
    const optionsClassNames = {}

    options.forEach((option, i) => {
      optionsClassNames[i] = 'first-options'
    }); 

    return (
      <div>
        {options.map((option, i) => (
          <div key={i} className={optionsClassNames[i]}>
            {this.formatSingleOption(option, verb, reco.hint, reco.medName, reco.medAmount, reco.medUnit, i, prev_option_num, reco.aglKey, recoIndex)}
          </div>
        ))}
      </div>
    );
  }

  formatSingleOption = (option, verb, hint, medName, medAmount, medUnit, option_num, prev_option_num, medAglKey, recoIndex) => {
    const option_index = option_num + prev_option_num
    const aglKey = option.aglKey ? option.aglKey : medAglKey;
    const medication = option.name ? option : {name : medName, amount: medAmount, unit: medUnit}
    return (
      <div className="container">
        <input type="radio" name="option" id={`${option}-${option_index}`} />
        <label htmlFor={`${option}-${option_index}`} className="radio-label" onClick={() => this.setMedication(medication, aglKey, verb, hint, recoIndex, option_num)}>
          { option.name ?
            <span className="radio">{option.name} {option.amount} {option.unit}</span>
            :
            <span className="radio">{option}</span>
          }
        </label>
      </div>
    ); 
  }

  recommendationItems = (recommendations) => {
    var optionTotal = 0;

    return recommendations.map((reco, i) => {
      //console.log("Reco is", reco)
      var recoBlob = null;

      if (typeof reco === 'string' || reco instanceof String) {
        // This block is probably not needed any more. All string recos are probably gone.
        const options_div = this.formatOptions([reco], "", reco, optionTotal, i);
        optionTotal += 1;
        recoBlob = <div className="explanation list">
                    <p className="main">
                    </p>
                    {options_div}
                  </div>
      } else if (reco.options.length === 0) {
        const options_div = this.formatOptions([reco.main], reco.verb, reco, optionTotal, i);
        optionTotal += 1;
        recoBlob = <div className="explanation list">
                    <p className="main">
                    </p>
                    {options_div}
                  </div>

      } else {
        const options_div = this.formatOptions(reco.options, reco.verb, reco, optionTotal, i)
        optionTotal += reco.options ? reco.options.length : 1;
        recoBlob = <div className="explanation list">
                    <div className="main">
                      <div>
                        {reco.main}
                      </div>
                    </div>
                    {options_div}
                  </div>
      }

      var recommendationDiv = null;
      if (i === 2) {
        // Decide whether to show the next option or the Show Other expander
        if (this.state.showOtherOptions) {
          recommendationDiv = 
            <div className="recommendation" key={i}>
              <p className="show-more" onClick={ () => this.setState({showOtherOptions: false})}>Hide other options  ∧</p>
                  <p className="or">OR</p>
                {recoBlob}
                {i < (recommendations.length-1) &&
                  <p className="or">OR</p>
                }
            </div>
        } else {
          recommendationDiv = 
            <div className="recommendation" key={i} onClick={ () => this.setState({showOtherOptions: true})}>
              <p className="show-more">Show other options  ∨</p>
            </div>
        }
      } else if ((i < 2) || (i > 2 && this.state.showOtherOptions)) {
        recommendationDiv = 
          <div className="recommendation" key={i}>
            {recoBlob}
            {i < (recommendations.length-1) && (i !== 1) &&
              <p className="or">OR</p>
            }
          </div>
      }
      return recommendationDiv;
    });
  }

  setMedication = (medication, aglKey, verb, hintOverride, recoIndex, optionNum) => {
    if (recoIndex === 0)
      this.setState( { chosenMedicationIsTopRecommendation: true });
    else
      this.setState( { chosenMedicationIsTopRecommendation: false });
    console.log("Choosing", medication, aglKey, recoIndex, optionNum)

    this.setState( { chosenMedication: medication, 
                     chosenMedicationVerb: verb, 
                     chosenMedicationAglKey: aglKey,
                     hintOverride: hintOverride 
                   })
  }

  // generateDocumentation = () => {
  //   this.setState( { showDocumentation: !this.state.showDocumentation})
  // }

  handlePhoneChange = (event) => {
    this.setState({phone: event.target.value});
  }

  handlePhoneClick = (event) => {
    event.preventDefault();
  }

  prefaceParagraphs= (prefaces) => {
    return (
      <div>
        {prefaces.map((preface, i) => (
          <p key={i} className="preface">
            {preface}
          </p>
        ))}
      </div>
    );
  }

  //function Listener(event) {
  Listener = (event) => {
    for (var type in event.data) {
        var payload = event.data[type];
        switch (type) {
            case "token":
                // this is the token passed down by Epic
                // which every post message needs to include
                this.epicToken = payload;
                break;
            case "error":
                // payload is an error string
                break;
            case "actions":
                // payload is a list of features (or actions)
                break;
            case "state":
                // payload is some state which you have saved
                // before AGL hibernated
                break;
            case "actionExecuted":
                // payload is a boolean set to "true" if the action
                // completed, "false" otherwise
                break;
            case "errorCodes":
                // payload is an array of all errors which might have been // encountered
                break;
            case "historyPackage":
                // payload is an object that contains the history state you saved
                // as well as a Boolean indicating whether Hyperspace came out of hibernation
                break;
            case "history":
                // payload is a string that corresponds to the history button the user clicked
                break;
            default:
                // if new properties get implemented which are not
                // handled above, you end up here
                break;
        }
    }
}

  onLoad = () => {
    // Add your listener
    window.addEventListener("message", this.Listener, false);
    // Request the initial handshake
    window.parent.postMessage({
        "action": "Epic.Clinical.Informatics.Web.InitiateHandshake"
    }, "*");
    //document.getElementById("p1").innerHTML = 'Listening...'
  }

  handleShowHomeBPInstructions = () => {
    if (!this.state.showHomeBPInstructions)
      logUsage(this.props.env, this.props.userId, this.props.patientMRN, this.props.encounter,  "ShowHomeBPInstructions");
    this.setState({showHomeBPInstructions: !this.state.showHomeBPInstructions})
  }

  handleShowDietInstructions = () => {
    if (!this.state.showDietInstructions)
      logUsage(this.props.env, this.props.userId, this.props.patientMRN, this.props.encounter,  "ShowDietInstructions");
    this.setState({showDietInstructions: !this.state.showDietInstructions})
  }

  handleNewBPGoal = (sbp, dbp) => {
    this.setState({showBPGoal: false})
    const homeSBP = getHomeSPB(sbp);
    const homeDBP = getHomeDPB(dbp);
    window.alert(`Target BP Goal for clinic is now set to ${sbp}/${dbp} and for home to ${homeSBP}/${homeDBP}`)
    this.props.handleBPGoal(sbp, dbp)
  }

  handleShowBPGoalModal = (event) => {
    this.setState({showBPGoal: true})
    event.stopPropagation();
  }


  handleClick = () => {
    this.setState({showBPGoal: false})
  }

  render() {
    console.log("Recommendations are: ", this.props.recommendations)
    const { showDocumentation, 
            hintOverride,
            chosenMedication,
            chosenMedicationVerb,
            showHomeMonitoring,
            phone
          } = this.state;

    return (
      <div id="Recommendations" onClick={this.handleClick}>
        <ReactTooltip 
          clickable={true} 
          place="right" 
          html={true} 
          className="tooltip-extra-class"
        />
        <div className="header">
          <span>Engage-Rx Recommendations</span>
        </div>
        <div className="reco-title-bar">
          <p className="reco-title">Medication Recommendations</p>
          {
            this.props.recommendations.tooltip &&
            <div className="tooltip"  data-tip={this.props.recommendations.tooltip}>
              i
            </div>
          }
          <div className="bp-goal">
            <p>BP goal</p>
            <button className="caret" onClick={this.handleShowBPGoalModal}><span className="label">&or;</span></button>
            { this.state.showBPGoal &&
              <BPGoal handleBPGoal={this.handleNewBPGoal}/>
            }
          </div>

        </div>
        <div className="reco-list">
          { this.props.recommendations.preface && 
              this.prefaceParagraphs(this.props.recommendations.preface)
          }
          { this.props.recommendations.document && 
              <a href={this.props.recommendations.document}>{this.props.recommendations.document}</a>
          }
          {this.props.recommendations.diagnosis === UNCONTROLLED_HYPERTENSION ?
            <ul>
              {this.recommendationItems(this.props.recommendations.recommendations)}
            </ul>
            :
            <Expander title="Hide medication recommendations" closedTitle="Show medication recommendations" subcontent={true} isClosed={true}>
              <ul>
                {this.recommendationItems(this.props.recommendations.recommendations)}
              </ul>
            </Expander>
          }
        </div>
        <div className="checkbox-list">
          <p className="reco-title">Order Checklist</p>
          <div className="with-textbox">
            <Checkbox borderStyle="no-border" label="Recommend home BP monitoring" name="home-monitoring" handler={() => this.setState({showHomeMonitoring: !showHomeMonitoring})}/>
            { showHomeMonitoring && 
            <div onClick={this.handlePhoneClick}>

            <form onClick={this.handlePhoneClick}>
               <input className="phone" type="text" name="phone" value={phone} onChange={this.handlePhoneChange} />
            </form>
            </div>
            }
          </div>
          { this.props.recommendations.diagnosis === OK &&
            <Checkbox label="Reassess in 3-6 months." name="hypotension" />
          }
          
          { this.props.age > 65 &&
            <Checkbox label="Assess for orthostatic hypotension given age > 65" name="hypotension" />
          }
          <Checkbox label="Check basic metabolic panel" name="metabolic" handler={() => this.setState({ basicMetabolicPanelNeeded: !this.state.basicMetabolicPanelNeeded})} />
          { this.props.recommendations.diagnosis !== OK &&
            <div>
              <Checkbox label="Schedule visit in 2-4 weeks and reassess" name="metabolic" />
            </div>
          }
          <Checkbox label="Diet Instructions" name="metabolic" handler={this.handleShowDietInstructions}/>
          { this.state.showDietInstructions &&
            <div className="dash">
              <h3 className="top">DASH Diet</h3>
              <p>
                The DASH diet is one of the best to help lower your blood pressure and 
                requires no special foods. You just need to know what foods you should 
                eat and others you should limit.
              </p>
              <ul>
                <li>
                  EAT THIS: vegetables, fruits, whole grains, fat-free or low-fat dairy, 
                  fish, poultry, beans, nuts and seeds, vegetable oils
                </li>
                <li>
                  LIMIT THIS: fatty meats, full-fat dairy, sugar sweetened beverages, 
                  sweets, salt, tropical oils (coconut, palm kernel, and palm oils), 
                  most packaged snacks
                </li>
              </ul>
              <p>
                DETAILS, TIPS, RECIPES:
              </p>
              <a target="_blank" rel="noopener noreferrer" href="https://www.nhlbi.nih.gov/education/dash-eating-plan">www.nhlbi.nih.gov/education/dash-eating-plan</a>

              <h3>Lifestyle Changes to Lower Blood Pressure</h3>
              <ul>
                <li>Eat a LOW SALT DIET of about 1 teaspoon of salt per day </li>
                <ul>
                  <li className="sub">A very low salt diet of 3/4 teaspoon of salt per day will help lower blood pressure even more</li>
                  <li className="sub">MORE INFO: <a target="_blank" rel="noopener noreferrer" href="https://www.heart.org/en/health-topics/high-blood-pressure/changes-you-can-make-to-manage-high-blood-pressure/shaking-the-salt-habit-to-lower-high-blood-pressure">www.heart.org/en/health-topics/high-blood-pressure/changes-you-can-make-to-manage-high-blood-pressure/shaking-the-salt-habit-to-lower-high-blood-pressure</a></li>
                </ul>
                <li>LIMIT ALCOHOL to no more than 1 drink daily (women) or 2 drinks daily (men)</li>
                <li>STOP SMOKING cigarettes</li>
                <li>Consider WEIGHT LOSS if you are overweight</li>
                <li>EXERCISE for 2 hrs and 30 minutes each week of exercises that get your heart pumping</li>
              </ul>
            </div>
          }
          <Checkbox label="Home BP Instructions" name="metabolic" handler={this.handleShowHomeBPInstructions}/>
          { this.state.showHomeBPInstructions &&
            <div>
              <p className="instructions">Follow these guidelines to make sure the measurement is accurate:</p>
              <ol>
                <li>You should check it before you take your medications in the morning or evening.</li>
                <li>Avoid coffee, exercise or cigarettes for 30 minutes prior to checking.</li>
                <li>Rest for at least 5 minutes before checking with both feet flat on the floor and your back supported by a chair.</li>
                <li>Your arm should be at heart level and resting on a table or other surface.</li>
                <li>Do not talk while your blood pressure is being checked.</li>
                <li>Check your blood pressure twice with 1-2 minutes in between and write down the numbers to keep track so you can discuss with your doctor at future visits</li>
              </ol>
              <p>
              MORE INFO:
              </p>
              <a target="_blank" rel="noopener noreferrer" href="https://www.heart.org/en/health-topics/high-blood-pressure/understanding-blood-pressure-readings/monitoring-your-blood-pressure-at-home">www.heart.org/en/health-topics/high-blood-pressure/understanding-blood-pressure-readings/monitoring-your-blood-pressure-at-home</a>
            </div>
          }
          { /* 
            this.props.recommendations.diagnosis !== OK &&
            <div>
              <Checkbox cstyle="bold" label="Generate documentation" name="metabolic" handler={this.generateDocumentation} />
            </div>
            */
          }
        </div>
        { 
          showDocumentation &&
          <Documentation medication={chosenMedication} verb={chosenMedicationVerb} conditions={this.props.conditions} age={this.props.age} gender={this.props.gender} diagnosis={this.props.recommendations.diagnosis} hint={hintOverride ? hintOverride : this.props.recommendations.documentHint} />
        }
        <div className="button-container">
          <button className="agree" onClick={this.agreeAndOrder}>
            Agree and Order
          </button>
        </div>
      </div>
    );
  }
}