import React from "react";
import './Medications.scss';
import { Expander } from "./Expander";
import { capitalizeFirst } from "./shared/utils.js";

function medicationItems(medications) {
  return medications.map((medication, i) => {
    const tabletStr = medication.quantity > 1 ? "tablets" : "tablet"
    let name;
    if (medication.medications && medication.medications.length > 1) {
      // TODO, make this work for multi tablets
      name = `${capitalizeFirst(medication.medications[0].name)} ${medication.medications[0].dosageAmount} ${medication.medications[0].dosageUnit}-${capitalizeFirst(medication.medications[1].name)} ${medication.medications[1].dosageAmount} ${medication.medications[1].dosageUnit}, ${medication.quantity} ${tabletStr} daily`;
    } else {
      if (medication.timesDaily > 1) {
        name = medication.type === null ? medication.displayName : `${medication.displayName} ${medication.dosageAmount} ${medication.dosageUnit}, 1 tablet, ${medication.timesDaily} times daily`;
      } else {
        name = medication.type === null ? medication.displayName : `${medication.displayName} ${medication.dosageAmount} ${medication.dosageUnit}, ${medication.quantity} ${tabletStr} daily`;
      }
    }
    return (<li key={i} className="name">{name}</li> )
  });
}

function adherence(medications) {
  return medications.map((medication, i) => ( 
    <li key={i} className="name">Yes</li> )
  );
}


export function Medications(props) {
  const activeHypertensionMedications = props.medications.filter(med => med.status === 'active')
  const activeOtherMedications = props.otherMedications.filter(med => med.status === 'active')
  const inactiveMedications = props.medications.filter(med => med.status !== 'active')

  return (
    <div id="Medications">
      <div className="medication-list top-list">
        <div className="names">
          <span className="subheader">Hypertension Related Medications</span>
          <ul>
            {medicationItems(activeHypertensionMedications)}
          </ul>
        </div>
        <div className="adherence">
          <span className="subheader">Adherent (Patient reported)</span>
          <ul>
            {adherence(activeHypertensionMedications)}
          </ul>
        </div>
      </div>
      <Expander title="Hide other medications" closedTitle="Show other medications" subcontent="true" isClosed={true}>
        <div className="medication-list">
          <div className="names">
            <ul>
              {medicationItems(activeOtherMedications)}
            </ul>
          </div>
        </div>
      </Expander>
      <Expander title="Hide inactive medications" closedTitle="Show inactive medications" subcontent="true" isClosed={true}>
        <div className="medication-list">
          <div className="names">
            <ul>
              {medicationItems(inactiveMedications)}
            </ul>
          </div>
        </div>
      </Expander>
    </div>
  );
}