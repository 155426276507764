import React from "react";
import { oauth2 as SMART } from "fhirclient";

/**
 * Typically the launch page is an empty page with a `SMART.authorize`
 * call in it.
 *
 * This example demonstrates that the call to authorize can be postponed
 * and called manually. In this case we use ReactRouter which will match
 * the `/launch` path and render our component. Then, after our page is
 * rendered we start the auth flow.
 */
const FHIR_URL = "https://launch.smarthealthit.org/v/r4/sim/eyJoIjoiMSIsImIiOiI5NTM1ZDdkNC0zMzk4LTQ4OGQtODNlYS1mZjNiN2EyZWRkYmYsMTJlZGI1YTAtZWM3MS00NWI3LWFmZjYtMGZlZjFjMzgyODgxLGQwMTkwNjUxLWI5YjAtNDUxMy04ZjNiLWQ1NDIzMTkyMjBkMSw4MDJlMTUwYy1jM2YzLTRhMmQtOTU0Zi05YjdhOTJiZTU5YmMsZGJmOTc5OGUtNGI1Mi00Y2Q5LWE5ZWItZWMzNjE0OWM4NTlhLDk4MjMzODRkLTIxMjAtNDc4ZS05YWIzLTYzNzVjNTk0MzQ3ZCxmMjMxNjc4OS0zMGM0LTRjMDYtOTE5My0wY2E1NDc1YmJiMWIsNDZkMzc4NWUtODY1Yi00NmE1LThlYTktODZhNzJhMTgzZGUzLDY3Y2JmMDkwLTRkZGItNDc5OS05OWZmLWEyOGFiZTI3NDBiMSw0OTQ3NDNhMi1mZWE1LTQ4MjctOGYwMi1jMmI5MWU0YTRjOWUsNGQ1NmFjMGEtYzMzOC00MGViLThiZGQtMzI1NjhiMjFhNGUzLDY4OTg5MmJkLWRjYmUtNDFmYy04NjUxLTM4YTFkMDg5Mzg1NCxhNjg4OWM2ZC02OTE1LTRmYWMtOWQyZi1mYzZjNDJiM2E4MmUsNzE4NDEzZTAtOWI5OC00YzM0LTk1NzUtZmRiNGU1MTc5NzM4IiwiaSI6IjEiLCJlIjoiMzZhNmUxNWItNzM0NS00MmFlLThhNTktN2EzNTA1OWViYjQ3In0/fhir";


export default class Launcher extends React.Component {
    /**
     * This is configured to make a Standalone Launch, just in case it
     * is loaded directly. An EHR can still launch it by passing `iss`
     * and `launch` url parameters
     */
    componentDidMount() {
        SMART.authorize({
            clientId: "my-client-id",
            scope: "launch/patient offline_access openid fhirUser patient/*.read patient/Observation.write",
            redirectUri: "./display",
            iss: FHIR_URL
        });
    }
    /**
     * Could also return `null` for empty page
     */
    render() {
        return "Launching...";
    }
}
