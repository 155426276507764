import React, {useState} from "react";
import './BulkReportInput.scss';

function BulkReportInput({token, env, mrnHandler}) {

  const [mrnText, setMrnText] = useState("")

  const handleTextInput = (event) => {
    setMrnText(event.target.value);
  }

  const generateReport = () => {
    const mrns = mrnText.split(/[\s,]+/).map(mrn => mrn.trim())
    mrnHandler(mrns)
  }

  return (
    <div className="BulkReportInput">
      <div className="header">
        <h1>EngageRx Bulk Report for BP Activate</h1>
      </div>
      <div className="data-entry">
        <h2>Enter/paste list of patient MRNs below:</h2>
        <textarea cols="80" rows="40" value={mrnText} onChange={handleTextInput} />
        <button className="generate" onClick={generateReport}>
           Generate Report
        </button>
      </div>
    </div>
  )
}

export default BulkReportInput;