import React from "react";
import HTN_MEDICATIONS from '../data/htn_meds.json';
import MED_RECOS from '../data/med_recommendations.json';
import AGL_KEYS from '../data/agl_order_keys.json';
import { ACR_RATIO_GOAL_GRAMS, ACR_RATIO_GOAL_MILLIMOLES } from "./constants";
var dayjs = require('dayjs')

var COMBO_MEDS = {}
loadComboMedData(COMBO_MEDS)

const SYSTOLIC = "SYSTOLIC"
const DIASTOLIC = "DIASTOLIC"
const CKD = "chronic kidney disease";
const CHF = "heart failure";

const MED_LIST = Object.keys(HTN_MEDICATIONS.medications);
const BRAND_LIST = Object.keys(HTN_MEDICATIONS.brands);
const MED_SET = new Set(MED_LIST);

const MED_CLASS_MAP = {
  "Beta-blocker" : "beta-blocker",
  "ACE inhibitor" : "ace",
  "ARB" : "arb",
  "Thiazide Diuretic" : "thiazide",
  "CCB" : "ccb",
  "Aldo Antagonist" : "aldo-antagonist"
}

const SUBMIT_FEEDBACK_API = 'https://engagerx.ucsf.edu/api/submit_feedback';
const LOG_USAGE_API = 'https://engagerx.ucsf.edu/api/log_usage';
const LOG_RECOMMENDATIONS_API = 'https://engagerx.ucsf.edu/api/log_recommendations';

//const LOG_RECOMMENDATIONS_API = 'http://localhost:8081/api/log_recommendations';
//const LOG_USAGE_API = 'http://localhost:8081/api/log_usage';
//const SUBMIT_FEEDBACK_API = 'http://localhost:8081/api/submit_feedback';

function getSpc(currentMed) {
  //console.log("In get SPC, incoming is:")
  //console.log(currentMed)
  const myMedComboKey = currentMed.medications.map((med) => {
    return med.name.toLowerCase()
  }).join("/")
  /*
  const myMedComboKey = currentMed.medications.map((med) => {
    return med.name.toLowerCase()
  }).sort().join("/")
  */
  //console.log(myMedComboKey)
  const spcTypes = Object.keys(MED_RECOS.spc)
  var matchingSPC = null
  spcTypes.forEach((spcType) => {
    MED_RECOS.spc[spcType].forEach((spc) => {
      //const medComboKey = spc.medications.sort().join("/").toLowerCase()
      const medComboKey = spc.medications.join("/").toLowerCase()
      if (medComboKey === myMedComboKey) {
        matchingSPC = spc
      }
    });
  });
  return matchingSPC;
}

function getSpcStep(spc, currentMed) {
  //console.log("In getSpcStep, currentMed is:", currentMed)
  const myDosage = currentMed.medications.map((med) => {
    return med.dosageAmount.toString()
  }).join("/");
  var currentStep = null;
  if (spc.intensification) {
    spc.intensification.forEach((step) => {
      if (myDosage === step.dosage && currentMed.quantity === step.tabs)  {
        currentStep = step;
      }
    });
  }

  //console.log("Current step is:")
  //console.log(currentStep);
  return currentStep;
}

function loadComboMedData(outputMeds) {
  const regex = new RegExp('_', 'g')
  const comboIngredients = Object.keys(HTN_MEDICATIONS.combos); 
  comboIngredients.forEach((combo) => {
    const ingredients = normalize(combo)
    outputMeds[ingredients] = HTN_MEDICATIONS.combos[combo].replace(regex, ' ')
  });
}

// Extract dosage info by parsing the display string
function extractMedicationData(medication, medicationName, medicationData) {
  //console.log("Extracting from:", medication, medicationData, medicationName);
  const start = medication.indexOf(medicationName);
  const instructions = medication.slice(start + medicationName.length)
  const dosage = instructions.match(/\d+\.?\d*/)
  var dosageAmount = ""
  var dosageUnit = ""
  if (dosage) {
    dosageAmount = dosage[0]
    //console.log("BLAH, dosageAmount is", dosageAmount);
    const stuffAfterAmount = instructions.slice(dosage.index + dosage[0].length)
    //console.log("BLAH, stuffAfter is", stuffAfterAmount);
    const nextWord = stuffAfterAmount ? stuffAfterAmount.match(/\b[A-Za-z]+\b/)[0] : ""
    dosageUnit = nextWord;
  }

  // If we had previously found medication meta data, use that instead
  dosageAmount = medicationData.dosageAmount || parseFloat(dosageAmount);
  const timesDaily = medicationData.timesDaily || 1;
  const quantity = medicationData.quantity || 1;

  return {name: medicationName, 
          displayName: medicationData.displayName,
          dosageAmount: dosageAmount, 
          dosageUnit: medicationData.dosageUnit || dosageUnit,
          totalDosage: dosageAmount * timesDaily * quantity,
          quantity: quantity,
          timesDaily: timesDaily,
          status: medicationData.status,
          type: null};
}

// Mega-function that converts what we know about a medication into our standard format
function medicationType(medication) {
  var lowerDisplayName = normalize(medication.displayName.toLowerCase())

  // Get rid of parenthetical expressions
  var parenthesesRegex = new RegExp("(([^(]+))");
  const parentheticalExpr = lowerDisplayName.match(parenthesesRegex);
  if (parentheticalExpr) {
    //console.log("Found parenthesis!")
    //console.log(parentheticalExpr);
    lowerDisplayName = lowerDisplayName.replace(parentheticalExpr, '');
    //console.log(`Now med is: ${lowerDisplayName}`)
  }

  lowerDisplayName=lowerDisplayName.replace(/\((.+?)\)/g, "")

  // Sometimes meds are listed by their brand name. Convert those to the 
  // medication name
  BRAND_LIST.forEach(brand_key => {
    var brandRegex = new RegExp("\\b" + brand_key + "\\b");
    const brand_name = lowerDisplayName.match(brandRegex);
    if (brand_name) {
      lowerDisplayName = lowerDisplayName.replace(brand_name, HTN_MEDICATIONS.brands[brand_key]);
    }
  });

  // Go through our medication list and see if there are medications we recognize
  const medNames = [];
  lowerDisplayName.split("-").forEach(medName => {
    for (let j=0; j<MED_LIST.length; j++) {
      const medNameKey = MED_LIST[j];
      if (medName.indexOf(medNameKey) !== -1) {
        medNames.push(medNameKey)
        break;
      }
    }
  });

  //console.log('medNames are', medNames)

  const quantity = medication.quantity ? medication.quantity : 1;
  var medInfo = null;

  if ((medNames.length === 0) || 
      (medication.prn) ||
      (lowerDisplayName.indexOf("injection") !== -1) || 
      (lowerDisplayName.indexOf("eye drops") !== -1)) {
    // No recognized medication. Just extract what info we can
    medInfo = extractMedicationData(lowerDisplayName, lowerDisplayName, medication);
  } else if (medNames.length === 1) {
    // Found one med. Return structured data with medication info
    medInfo = extractMedicationData(lowerDisplayName, medNames[0], medication)
    //console.log("extracted data is: ", medInfo)
    medInfo['type'] = HTN_MEDICATIONS.medications[medNames[0]];
    medInfo['displayName'] = medInfo.type === null ? capitalizeFirst(medInfo.displayName.toLowerCase()) : toTitleCase(medInfo.name)
  } else if (medNames.length > 1) {
    // Found multiple medications. This is Single Pill Combination (SPC)
    //const allDisplayNames = lowerDisplayName.split("-").sort();
    const allDisplayNames = lowerDisplayName.split("-");
    //console.log("BLAH, found an SPC", allDisplayNames, medNames)

    const combinedMeds = medNames.join(" / ")
    const sortedCombinedMedsKey = [...medNames].sort().join(" / ");
    medInfo = {displayName: toTitleCase(combinedMeds), type: COMBO_MEDS[sortedCombinedMedsKey], medications: [], quantity: quantity}
    //console.log("BLAH, medInfo is", medInfo)
    var gotAllDosages = true;
    for (let j=0; j<medNames.length; j++) {
      //console.log("Mike, medNames are:", medNames)
      let spcMedData = extractMedicationData(allDisplayNames[j], medNames[j], medication);
      spcMedData.type = HTN_MEDICATIONS.medications[medNames[j]]
      if (!spcMedData.dosageAmount) {
        gotAllDosages = false;
      }
      medInfo.medications.push(spcMedData);
      medInfo.status = spcMedData.status;
    }
    if (!gotAllDosages) {
      // Backup logic in case the SPC data is stored weird and we haven't figured it out yet
      // Look for some string like 10-20 and grab the dosages (e.g. 10 & 20)
      var dosageRegEx = /(\b[\d\.]+)-([\d\.]+)\b/
      const matches = lowerDisplayName.match(dosageRegEx)
      if (matches && matches.length > 2) {
        medInfo.medications[0].dosageAmount = parseFloat(matches[1])
        medInfo.medications[0].dosageUnit = "mg" // Just a guess
        medInfo.medications[0].totalDosage = medInfo.medications[0].dosageAmount * medInfo.medications[0].quantity * medInfo.medications[0].timesDaily  
        medInfo.medications[1].dosageAmount = parseFloat(matches[2])
        medInfo.medications[1].dosageUnit = "mg" // Just a guess
        medInfo.medications[1].totalDosage = medInfo.medications[1].dosageAmount * medInfo.medications[1].quantity * medInfo.medications[1].timesDaily  
      }
    }
  }
  //console.log("Mike, medicationType returning", JSON.parse(JSON.stringify(medInfo)))
  return medInfo;
}

function normalize(medication) {
  const tmpName = medication.toLowerCase().replace('hctz', 'hydrochlorothiazide').replace('hcl', 'hydrochloride');
  if (tmpName.indexOf(" / ") > -1) {
    return tmpName.split(" / ").join(" / ");
  } else return tmpName;
}

function bpValue(measurements, kind=SYSTOLIC, index=0) {
  if (measurements === null || measurements.length <= index ) return null;
  return Math.round( kind === SYSTOLIC ? measurements[index].systolic : measurements[index].diastolic)
}

function bpDateValue(measurements, index=0) {
  if (measurements === null || measurements.length <= index ) return null;
  return new Date(measurements[index].date).toISOString().substring(0, 10)
}

function hasTooHighACR(acrMeasurements) {
  var too_high = false;
  if (acrMeasurements && acrMeasurements.length > 0) {
    if (acrMeasurements[0].unit === "mg/g") {
       if (acrMeasurements[0].value >= ACR_RATIO_GOAL_GRAMS)  {
         too_high = true;
       }
    } else {
       if (acrMeasurements[0].value >= ACR_RATIO_GOAL_MILLIMOLES)  {
         too_high = true;
       }
    }
  }
  return too_high;
}

function historyOfCHF(conditions) {
  var hasHeartIssues = false;
  conditions.forEach(condition => {
    const name =condition.name.toLowerCase();
    if (name.indexOf(CHF) !== -1) {
      console.log("FOUND CHF on", condition)
      hasHeartIssues = true;
    }
  });

  return hasHeartIssues;
}

function historyOfCKD(conditions) {
  var hasHeartIssues = false;
  conditions.forEach(condition => {
    const name =condition.name.toLowerCase();
    if (name.indexOf(CKD) !== -1) {
      hasHeartIssues = true;
    }
  });

  return hasHeartIssues;
}

function formatBP(bpMeasurements, index) {
  return `(SBP:${Math.round(bpMeasurements[index].systolic)}  DBP:${Math.round(bpMeasurements[index].diastolic)})`;
}

function formatDate(aDate) {
  return `${aDate.month()+1}.${aDate.date()}.${aDate.year()}`;
}


function formatHomeBP(averageSbp, averageDbp) {
  return `(SBP:${Math.round(averageSbp)}  DBP:${Math.round(averageDbp)})`;
}

function formatACR(acrMeasurements, index) {
  const value = acrMeasurements[index].unit === 'mg/g' ? parseInt(acrMeasurements[index].value) : acrMeasurements[index].value;
  return `(${value} ${acrMeasurements[index].unit})`;
}

function age(patient) {
  const birthday = new Date(patient.birthDate)
  var ageDifMs = Date.now() - birthday.getTime();
  var ageDate = new Date(ageDifMs); 
  return Math.abs(ageDate.getUTCFullYear() - 1970);
}

function phoneNumber(patient) {
  var number = "";
  if (patient.telecom && patient.telecom.length > 0) {
    number = patient.telecom[0].value;
  }
  return number;
}

function inRange(measurement, startDate, endDate) {
  const date = new Date(measurement.date)
  if (endDate) {
    return ((date < startDate) && (date >= endDate));
  } else {
    return (date < startDate);
  }
}

function averageHomeBP(homeMeasurements, averageSbp, averageDbp, clinicMeasurements) {
  const result = {};
  if (averageSbp && averageDbp) {
    result['sbp'] = averageSbp;
    result['dbp'] = averageDbp;
    return result;
  } else if (homeMeasurements != null && homeMeasurements.length > 0) {
    const startDate = new Date(clinicMeasurements[0].date)
    const endDate = clinicMeasurements.length > 1 ? new Date(clinicMeasurements[1].date) : null
    const validMeasurements = homeMeasurements.filter(measurement => (inRange(measurement, startDate, endDate)));

    //console.log("Valid measurements are:")
    //console.log(validMeasurements);

    const computedSbpAverage = validMeasurements.reduce((acc, measurement) => acc + measurement.systolic, 0) / validMeasurements.length
    const computedDbpAverage = validMeasurements.reduce((acc, measurement) => acc + measurement.diastolic, 0) / validMeasurements.length
    result['sbp'] = computedSbpAverage;
    result['dbp'] = computedDbpAverage;
    return result;
  }
  return null;
}


function patientName(name) {
  let entry =
    name.find(nameRecord => nameRecord.use === "official") || name[0];
  if (!entry) {
    return <span className="patient-name">No Name</span>;
  }
  return <span className="patient-name">{entry.given.join(" ") + " " + entry.family}</span>;
}

function patientFirstName(name) {
  let entry =
    name.find(nameRecord => nameRecord.use === "official") || name[0];
  //console.log(`In patientFirstName, entry is ${entry}`)
  if (!entry) {
    return "No Name";
  }
  return entry.given.join(" ");
}

function patientLastName(name) {
  let entry =
    name.find(nameRecord => nameRecord.use === "official") || name[0];
  //console.log(`In patientLastName, entry is ${entry}`)
  if (!entry) {
    return "No Name";
  }
  return entry.family;
}

function mean(values) {
  var sum = 0
  values.forEach(value => {
    sum += value
  });
  return Math.round(sum/values.length);
}

function median(values) {
  if(values.length ===0) return 0;
  values.sort(function(a,b){
    return a-b;
  });

  var half = Math.floor(values.length / 2);
  if (values.length % 2)
    return values[half];

  return Math.round((values[half - 1] + values[half]) / 2.0);
}

function applicableHomeBPMeasurements(measurements) {
  //console.log("App incoming measurements are:", measurements)
  // Assumes measurements are in chrono order, most recent first

  /* 
    Via the Algorithm doc, home BP measurements are valid for determining hypertension 
    level if there are:
       1. 5 days of home BP over 7 consecutive days
       2. Only look at home BP in the last 14 days before current clinic visit
   */
  let startDate = dayjs()
  let endDate;
  const lastStartDate = startDate.subtract(9, 'day');
  let applicable = []

  // See if we can find data that matches the above. Start with today:
  while (startDate > lastStartDate) {
    endDate = startDate.subtract(7, 'day');
    let index = 0;
    //console.log("App: Gonna look at this index: ", index,  measurements[index])
    while (index < measurements.length) {
      if (dayjs(measurements[index]["date"]) <= endDate) {
        // Date is too old. Done with this date range
        break;
      } else if (dayjs(measurements[index]["date"]) > startDate) {
        // Date is too new. Keep looking further down
        index +=1;
        continue;
      }
      //console.log("App: Looking at this index: ", measurements[index])
      applicable.push(measurements[index])
      index +=1;
    };
    if (applicable.length >= 5) {
      //console.log("Boom! Applicable!");
      break;
    }
    startDate = startDate.subtract(1, 'day')
    applicable = [];
  }

  //console.log("MIKE, Will return these applicable measurements: ", applicable)
  return applicable;
}

function getValue(measurements, index, bpType) {
  if (measurements.length > index) {
    return measurements[index][bpType];
  } else {
    return null;
  }
}

function clinicBPStats(measurements) {
  var firstDate = null;
  var lastDate = null;
  var highSystolic = 0;
  var highDiastolic = 0;
  var lowSystolic = 999999;
  var lowDiastolic = 999999;
  const systolics = [];
  const diastolics = [];

  console.log("In clinicBPStats, measurements are:", measurements)
  for (let i = 0; i < measurements.length; i++) {
    systolics.push(measurements[i].systolic)
    diastolics.push(measurements[i].diastolic)
    firstDate = firstDate || measurements[i].date;
    lastDate = measurements[i].date;
    highSystolic = highSystolic > measurements[i].systolic ? highSystolic : measurements[i].systolic;
    highDiastolic = highDiastolic > measurements[i].diastolic ? highDiastolic : measurements[i].diastolic;
    lowSystolic = lowSystolic < measurements[i].systolic ? lowSystolic : measurements[i].systolic;
    lowDiastolic = lowDiastolic < measurements[i].diastolic ? lowDiastolic : measurements[i].diastolic;
  }

  return { "firstDate": dayjs(firstDate),
            "prevDate": dayjs(getValue(measurements, 1, 'date')),
            "prevPrevDate": dayjs(getValue(measurements, 2, 'date')),
            "lastDate": dayjs(lastDate),
            "systolicMean": mean(systolics),
            "diastolicMean": mean(diastolics),
            "highSystolic": highSystolic,
            "lowSystolic": lowSystolic,
            "highDiastolic": highDiastolic,
            "lowDiastolic": lowDiastolic,
            "currentSystolic": getValue(measurements, 0, 'systolic'),
            "currentDiastolic": getValue(measurements, 0, 'diastolic'),
            "prevSystolic": getValue(measurements, 1, 'systolic'),
            "prevDiastolic": getValue(measurements, 1, 'diastolic'),
            "prevPrevSystolic": getValue(measurements, 2, 'systolic'),
            "prevPrevDiastolic": getValue(measurements, 2, 'diastolic')
  }
}


function homeBPStats (measurements, averageSbp, averageDbp) {
  //console.log("Mike, in homeBPStats, measurements are", measurements)
  //console.log("In homeBPStats")
  //console.log(`  averages are: ${averageSbp}, ${averageDbp}`);
  //console.log("  Measurements are", measurements);
  // Assumes measurements are in chrono order, most recent first
  let firstDate = null;
  let lastDate = null;
  let highSystolic = 0;
  let highDiastolic = 0;
  let lowSystolic = 999999;
  let lowDiastolic = 999999;
  const systolics = [];
  const diastolics = [];

  if (averageSbp && averageDbp) {
    return { 
      "systolicMean": averageSbp,
      "diastolicMean": averageDbp
    }
  }

  measurements.forEach(dayData => {
    const dataDate = dayjs(dayData["date"]);
    const dailySystolics = [];
    const dailyDiastolics = [];

    firstDate = dataDate;
    lastDate = lastDate || dataDate;
    dayData["measurements"].forEach(measurement => {
      dailySystolics.push(measurement["systolic"]);
      dailyDiastolics.push(measurement["diastolic"]);
    })

    const systolicMean = mean(dailySystolics);
    const diastolicMean = mean(dailyDiastolics);
    systolics.push(systolicMean);
    diastolics.push(diastolicMean);

    highSystolic = highSystolic > systolicMean ? highSystolic : systolicMean;
    highDiastolic = highDiastolic > diastolicMean ? highDiastolic : diastolicMean;
    lowSystolic = lowSystolic < systolicMean ? lowSystolic : systolicMean;
    lowDiastolic = lowDiastolic < diastolicMean ? lowDiastolic : diastolicMean;
  });

  return { "firstDate": firstDate,
            "lastDate": lastDate,
            "systolicMean": mean(systolics),
            "diastolicMean": mean(diastolics),
            "systolicMedian": median(systolics),
            "diastolicMedian": median(diastolics),
            "highSystolic": highSystolic,
            "lowSystolic": lowSystolic,
            "highDiastolic": highDiastolic,
            "lowDiastolic": lowDiastolic
  }
}

function toTitleCase (phrase)  {
  return phrase
    .toLowerCase()
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

function capitalizeFirst(string) {
  return string.toLowerCase().charAt(0).toUpperCase() + string.slice(1);
}

function sortMeasurements(measurements) {
  const sorted = measurements.concat().sort(function (a, b) {
    return dayjs(b.date).valueOf() - dayjs(a.date).valueOf();
  });
  return sorted;
}

function hasItemsInCommon(setA, setB) {
  var hasCommon = false;
  if (setA && setB) {
    for (let elem of setB) {
      if (setA.has(elem)) {
        hasCommon = elem;
        break;
      }
    }
  }
  return hasCommon;
}

function convertMedTypeToSet(medType) {
  const types = new Set();
  if (medType.startsWith("combo")) {
    const tmpTypes = medType.split(" ");
    types.add(tmpTypes[1].toLowerCase())
    types.add(tmpTypes[2].toLowerCase())
  } else {
    if (MED_CLASS_MAP.hasOwnProperty(medType)) {
      types.add(MED_CLASS_MAP[medType])
    } else {
      types.add(medType.toLowerCase())
    }
  }
  return types;
}

function convertAllergyNames(allergies) {
  const allergyMap = {};
  allergies.forEach((allergy_item) => {
    const allergy = allergy_item.toLowerCase();
    //console.log("processing this allergy", allergy)

    if (MED_SET.has(allergy)) {
      //console.log("Found it!")
      // Case where it's a medication name and not a medication class
      // Map the med class internal code to the corresponding medication class
      //allergyMap[MED_CLASS_MAP[HTN_MEDICATIONS.medications[allergy]]] = HTN_MEDICATIONS.medications[allergy];
      allergyMap[MED_CLASS_MAP[HTN_MEDICATIONS.medications[allergy]]] = allergy;
    } else if (allergy.startsWith("ace")) {
      allergyMap["ace"] = allergy
    } else if (allergy.startsWith("ccb")) {
      allergyMap["ccb"] = allergy
    } else if (allergy.startsWith("calcium channel block")) {
      allergyMap["ccb"] = allergy
    } else if ((allergy.startsWith("beta-adrenergic")) ||
              (allergy.startsWith("beta-blockers"))) {
      allergyMap["beta-blocker"] = allergy
    } else if (allergy.startsWith("thiazide")) {
      allergyMap["thiazide"] = allergy
    } else if (allergy.startsWith("arb")) {
      allergyMap["arb"] = allergy
    }
  });
  return allergyMap;
}

function searchUrlParams (name) {
  var results = new RegExp('[?&]' + name + '=([^&#]*)').exec(window.location.href);
  if (results==null){
     return null;
  }
  else{
     return decodeURI(results[1]) || 0;
  }
}

function formatConditions(conditions) {
  var phrase = "";
  //console.log("Conditions are:")
  //console.log(conditions);
  conditions.forEach((condition, index) => {
    var name = condition.name.replace(" (disorder)", "");
    /*
    if (name.toLowerCase().indexOf('kidney') !== -1) {
      name = 'chronic kidney disease';
    } else if (condition.name.toLowerCase().indexOf('congestive heart failure') !== -1) {
      name = 'congestive heart failure';
    } else if (condition.name.toLowerCase().indexOf('albuminuria') !== -1) {
      name = 'albuminuria';
    } else if (condition.name.toLowerCase().indexOf('myocardial') !== -1) {
      name = 'myocardial infarction';
    }
    */

    switch (index) {
      case 0:
        phrase = name;
        break;
      case 1:
        phrase = conditions.length === 2 ? phrase + " and " + name : phrase + ", " + name;
        break;
      default:
        phrase = conditions.length === (index + 1) ? phrase + ", and " + name : phrase + ", " + name;
    }
  });

  if (phrase === "") {
    phrase = "no relevant medical conditions";
  }

  return phrase;
}

function rationaleWithSpacing(existingRationale, newRationale) {
  if (existingRationale) 
    return existingRationale + "<br><br>" + newRationale;
  else
    return newRationale;
}


function submitFeedback(env, userId, patientId, encounter, text) {
  fetch(SUBMIT_FEEDBACK_API, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      env: env,
      patientMRN: patientId,
      userId: userId,
      encounter: encounter,
      text: text}) 
  })
  .catch(error => console.error('Error:', error))
  .then(response => {
    return response.json()
  })
  .then((data) => {
    console.log("Done submitting feedback");
  });
}

function logUsage(env, userId, patientId, encounter, data) {
  //console.log("Going to log usage data for", env, userId, patientId, encounter, data)
  fetch(LOG_USAGE_API, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      env: env,
      patientMRN: patientId,
      userId: userId,
      encounter: encounter,
      data: data}) 
  })
  .catch(error => console.error('Error:', error))
  .then(response => {
    if (response)
      return response.json();
    else 
      return {};
  })
  .then((data) => {
    console.log("Done logging data");
  });
}

function logRecommendations(env, userId, patientId, encounter, data) {
  const recos = extractRecommendationsOnly(data)
  //console.log("Going to log recommendations data for", env, userId, patientId, encounter, recos)
  fetch(LOG_RECOMMENDATIONS_API, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      env: env,
      patientMRN: patientId,
      userId: userId,
      encounter: encounter,
      data: recos}) 
  })
  .catch(error => console.error('Error:', error))
  .then(response => {
    if (response)
      return response.json();
    else 
      return {};
  })
  .then((data) => {
    console.log("Done logging recommendations");
  });
}

function getDateFromString(dateString) {
  const aDate = new Date(dateString);
  return aDate.getDate()+"-"+(aDate.getMonth()+1)+"-"+aDate.getFullYear();
}

function normalizeClinicBpMeasurements(measurements) {
  if (measurements.length < 2)
    return measurements;

  const tmpMeas = [...measurements];
  const firstDate = getDateFromString(tmpMeas[0].date)
  const secondDate = getDateFromString(tmpMeas[1].date)
  if (firstDate === secondDate) {
    if (tmpMeas[0].systolic < tmpMeas[1].systolic) {
      // First measurement is lower. Remove 2nd one;
      tmpMeas.splice(1, 1)
    } else {
      tmpMeas.splice(0, 1)
    }
  }
  return tmpMeas;
}

function getAGLKey(medication, pillDosage, unit, timesPerDay) {
  var key = `${medication.toLowerCase()}|${pillDosage} ${unit}|${timesPerDay ? timesPerDay: 1}`;
  key = key.replace('hctz', 'hydrochlorothiazide')
  console.log("AGL key is", key, "med is", AGL_KEYS[key])
  return AGL_KEYS[key];
}

function getEarliestDataDate() {
  var aDate = new Date();
  aDate.setFullYear(aDate.getFullYear() - 1);
  //aDate.setMonth(aDate.getMonth() - 1);
  return  aDate.toISOString().split('T')[0];
}

function extractRecommendationsOnly(data) {
  const recos = data.recommendations.map(reco => {
    let main = reco.main;
    let options;
    if (reco.options && reco.options.length === 1 && (typeof reco.options[0]) === 'string' ) {
      main = reco.options[0]
    } else {
      options = reco.options.map(option => {
        return {
          name: option.name,
          amount: option.amount,
          unit: option.unit,
          numTablets: option.numTables || 1
        }
      })
    }
    return {
      main: main,
      options: options,
    }
  })
  return recos
}

function getStorageKey(patientId, userId, field) {
  return `${patientId}-${userId}-${field}`
}

function getHomeSPB(clinicSBP) {
  if (clinicSBP <= 130)
    return clinicSBP;
  else
    return clinicSBP - 5;
}

function getHomeDPB(clinicDBP) {
  if (clinicDBP <= 80)
    return clinicDBP;
  else
    return clinicDBP - 5;
}

function bpaDate(aDate) {
  const tmpDate = aDate ? aDate : dayjs();
  return tmpDate.format("MM/DD/YYYY")
}

function homeAddress(patient) {
  let address = {}
  if (patient && patient.address && patient.address.length > 0) {
    patient.address.forEach((addr) => {
      if (addr.use === "home") {
        address = addr
      };
    });
  }
  return address;
}



export { SYSTOLIC, DIASTOLIC, CKD, CHF,
  patientName, patientFirstName, patientLastName, homeAddress,
  medicationType, rationaleWithSpacing, logRecommendations,
  bpValue, bpDateValue, historyOfCHF, historyOfCKD, formatBP, 
  formatHomeBP, age, formatACR, averageHomeBP, getSpc, getSpcStep, 
  hasTooHighACR, mean, median, formatDate, homeBPStats, searchUrlParams,
  toTitleCase, capitalizeFirst, sortMeasurements, hasItemsInCommon, 
  convertAllergyNames, applicableHomeBPMeasurements, formatConditions,
  phoneNumber, convertMedTypeToSet, logUsage, submitFeedback, 
  normalizeClinicBpMeasurements, getAGLKey, getEarliestDataDate, 
  getStorageKey, getHomeDPB, getHomeSPB, bpaDate, clinicBPStats};
