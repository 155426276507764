import React from "react";
import Patient from './Patient';
import { searchUrlParams } from './shared/utils.js'

const LOCAL_AUTH='https://engagerx.ucsf.edu/api/authenticate?token=';

export default class UCSFLauncher extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
      accessToken: null,
      encounter: null,
      patientId: null
    };

    // Passed to EngageRx by the SmartButton launcher
    this.token = null;
    const token_str = searchUrlParams('launch')
    if (token_str) {
      this.token = token_str
    } else {
      console.log("No token string given");
    }
  
  }

  componentDidMount() {
      this.authenticate(this.token, this.props.env)
  }

  authenticate = (token, env) => {
    const start = new Date();
    console.log(`In authenticate using this token: ${token} in env ${env}`)
    const url = `${LOCAL_AUTH}${token}&env=${env}`
    console.log(`sending to this url ${url}`)

    fetch(url, {
      method: 'GET'
    })
    .catch(error => console.error('Error:', error))
    .then(response => response.json())
    .then((data) => {
      console.log(`In authenticate got this access_token: ${data.access_token}`)
      this.setState({accessToken: data.access_token, patientId: data.patient, encounter: data.encounter})
      console.log(`Authenticating took: ${new Date() - start}`);
    });

  }

  render() {
    const { accessToken, patientId, encounter } = this.state;
    console.log("In render have this accessToken")
    console.log(accessToken);

    return (
      <div id="UCSFLaunechr">
        { accessToken ? 
         <Patient patientId={patientId} accessToken={accessToken} env={this.props.env} encounter={encounter} />
        :
          <p>Initializing access...</p>
        }
      </div>
    )
  }
}
