import React from "react";
import Patient from './Patient';
import { searchUrlParams } from './shared/utils.js'

//const LOCAL_AUTH='https://engagerx.ucsf.edu/api/authenticate?token=';
//const LOCAL_AUTH='https://engagerx.ucsf.edu/api/authenticate_local?patientMRN='
const LOCAL_AUTH='http://localhost:8081/api/authenticate_local?patientMRN='
//const LOCAL_AUTH='http://192.168.4.24:8081/api/authenticate_local?patientMRN='

export default class DebugLauncher extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
      accessToken: null,
      //patientMRN: props.match.params.patientId,
      patientId: null
    };

    console.log("Mike, debug props are: ", props);

    this.patientMRN = null;
    const patient_str = searchUrlParams('patientMRN')
    if (patient_str) {
      this.patient = patient_str
    } else {
      console.log("No patient string given");
    }

  }

  componentDidMount() {
      //this.authenticate(this.token)
      this.authenticate(this.props.env)
  }

  authenticate = (env) => {
    //console.log(`In authenticate using this token: ${token}`)
    const url = `${LOCAL_AUTH}${this.patient}&env=${env}`
    console.log(`sending to this url ${url}`)

    fetch(url, {
      method: 'GET'
    })
    .catch(error => console.log('Error:', error))
    .then(response => response.json())
    .then((data) => {
      console.log("In authenticate got this data returned");
      console.log(data)

      console.log(`In authenticate got this access_token: ${data.access_token}`)
      this.setState({accessToken: data.access_token, patientId: data.patient})
    });

  }

  render() {
    const { accessToken, patientId } = this.state;
    console.log("In render have this accessToken")
    console.log(accessToken);

    return (
      <div id="UCSFLaunechr">
        { accessToken ? 
         <Patient patientId={patientId} accessToken={accessToken} env={this.props.env}/>
        :
          <p>Initializing access...</p>
        }
      </div>
    )
  }
}
