import React from "react";
import { CSVLink } from "react-csv";
import './BPACSV.scss';

function BPACSV({data}) {

  return (
    <div className="BPACSV">
      {data ? 
        <div className="download">
          <CSVLink data={data} filename={"BPActivate.csv"}>Download CSV</CSVLink>
        </div>
        :
        <h1>Loading...</h1>
      }
    </div>
  )
}

export default BPACSV;