import React from 'react';
import {Router, Route} from 'react-router-dom'
import Display from './Display'
import Launcher from './Launcher'
import Status from './Status'
import UCSFLauncher from './UCSFLauncher'
import UCSFBulkReportLauncher from './UCSFBulkReportLauncher'
import DebugLauncher from './DebugLauncher'
import BulkReportInput from './BulkReportInput'
import BPActivateReport from './BPActivateReport'
import history from './history'
import './App.scss';
import { searchUrlParams } from './shared/utils.js'

class App extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      patientMRN: null,
    };

    this.env = '';
    const env_str = searchUrlParams('env')
    if (env_str) {
      this.env = env_str
    } else {
      console.log("No env string given");
    }
  }

  
  render() {
    console.log(`In App.js render, patient id is: ${this.state.patientId}`)
    return (
      <div id="App">
        <Router history={history} >
          <Route exact path='/' render={() => <Launcher />} />
          <Route exact path='/status/' component={Status} />
          <Route path='/launch/'>
            <UCSFLauncher env={this.env}/>
          </Route> 
          <Route path='/bulk-launch/'>
            <UCSFBulkReportLauncher env={this.env}/>
          </Route> 
          <Route path='/debug/'>
            <DebugLauncher env={this.env} />
          </Route> 
          <Route exact path='/display' render={() => <Display />} />
          <Route exact path='/bpa' render={() => <BPActivateReport />} />
        </Router>
      </div>
    );
  }
}

export default App;