import { hasTooHighACR, hasItemsInCommon, getEarliestDataDate, CKD, CHF } from './shared/utils.js'
var dayjs = require('dayjs')

const LOCAL_OBSERVATION='https://engagerx.ucsf.edu/api/observations';
const LOCAL_PATIENT='https://engagerx.ucsf.edu/api/patient';
const LOCAL_MEDICATION='https://engagerx.ucsf.edu/api/medications';
const LOCAL_CONDITION='https://engagerx.ucsf.edu/api/conditions';
const LOCAL_ALLERGY='https://engagerx.ucsf.edu/api/allergies';

// const LOCAL_OBSERVATION='http://192.168.4.24:8081/api/observations';
// const LOCAL_PATIENT='http://192.168.4.24:8081/api/patient';
// const LOCAL_MEDICATION='http://192.168.4.24:8081/api/medications';
// const LOCAL_CONDITION='http://192.168.4.24:8081/api/conditions';
// const LOCAL_ALLERGY='http://192.168.4.24:8081/api/allergies'; 

// const LOCAL_OBSERVATION='http://localhost:8081/api/observations';
// const LOCAL_PATIENT='http://localhost:8081/api/patient';
// const LOCAL_MEDICATION='http://localhost:8081/api/medications';
// const LOCAL_CONDITION='http://localhost:8081/api/conditions';
// const LOCAL_ALLERGY='http://localhost:8081/api/allergies'; 

const MILLIS_IN_A_DAY = 86400000;

const LAB_LOIN_CODES = new Set([
  '17856-6',   // Hemoglobin
  '2160-0',    // Creatinine
  '57698-3',   // Lipid panel
  '2093-3',    // Cholesterol in serum or plasma
  '2571-8',    // Triglyceride in serum or plasma
  '2085-9',    // Cholesterol in HDL in serum or plasma
  '18262-6',   // Cholesterol in LDL in serum or plasma 
  '13458-5',   // Cholesterol in VLDL in serum or plasma
  '13457-7'    // LDL
])

const CKD_CODES = ["D63.1", "E08.22", "E09.22", "E10.22", "E11.22", 
  "E13.22", "I12", "I13", "N18", "O10.2", "O10.3", "Q61"];
const CHF_CODES = ["I09.81", "I11.0", "I13.0", "I13.2", "I50", "I97.13"];

  const getLoinCodes = (client, obv) => {
    var codes = new Set();
    if (client)
      codes.add(client.getPath(obv, "code.coding.0.code"))
    else if (obv.code && obv.code.coding) {
      obv.code.coding.forEach(codeCandidate => {
        if (codeCandidate.system === "http://loinc.org")
          codes.add(codeCandidate.code);
      });
    } else if (obv.code)
      codes.add(obv.code.text);

    return codes;
  }

  const getObservationOrder = (code) => {
    let order;
    switch (code) {
      case '17856-6':  // Hemoglobin
        order = 0.51
        break;
      case '2571-8':  // Triglyceride in serum or plsma
        order = 0.52
        break;
      case '2160-0':    // Creatinine
        order = 0.45
        break;
      case '13457-7':  // LDL
        order = 0.8
        break;
      case '2093-3':  // Cholesterol in serum or plasma
      case '2085-9':  // Cholesterol in HDL in serum or plasma
      case '18262-6':  // Cholesterol in LDL in serum or plasma 
      case '13458-5':  // Cholesterol in VLDL in serum or plasma
        order = 0.9
        break;
      default:
        order = 0.5
    }
    return order;
  }

  const processObservationValue = (obv) => {
    const observationData = {}; 
    observationData['referenceRange'] = obv.referenceRange;
    observationData['date'] = obv.effectiveDateTime;
    if (obv.valueQuantity) {
      observationData['value'] = obv.valueQuantity.value;
      observationData['unit'] = obv.valueQuantity.unit;
    } else if (obv.valueString) {
      observationData['value'] = Number(obv.valueString.replace(">", "").replace("<", ""))
      observationData['displayValue'] = obv.valueString;
    }
    observationData['order'] = 0.5;
    return observationData;
  }

  const extractMeasurementsFromObservations = (client, observations) => {
    const datedBPMeasurements = {}
    const datedEGFRMeasurements = {}
    const datedACRMeasurements = {}
    const datedLVEFMeasurements = {}
    const datedSaltMeasurements = {}
    const datedSmokingMeasurements = {}
    const datedOtherMeasurements = {}
    // Structure of observations differs between public sandbox
    // and the proxied UCSF one
    console.log("Here are all observations", observations);
    const observationList = observations.observations || observations
    console.log("this many obvs", observationList.length);
    let index = 0;
    observationList.forEach(obv => {
      index = index + 1;
      //console.log("MIKE: started processing observation: ", obv, index)

      var observationCodes = getLoinCodes(client, obv)
      //console.log("MIKE: Observation codes are:", observationCodes);
      /*
      observationCodes.forEach(elem => {
        console.log(elem, index)
      });
      */

      if (observationCodes.has("33914-3") || observationCodes.has("48642-3") || observationCodes.has("48643-1")) {
        // Effective glomerular filtration rate
        const observationData = processObservationValue(obv)
        if (observationData.unit)
          observationData.unit = observationData.unit.replace('/{1.73_m2}', '');
        observationData['type'] = 'eGFR'
        observationData['order'] = 0.48
        datedEGFRMeasurements[obv.effectiveDateTime + 'egfr'] = observationData;
      } else if (observationCodes.has("72166-2")) {
        // console.log(obv)
        // Smoking status
        const observationData = {}; 
        observationData['date'] = obv.effectiveDateTime;
        observationData['value'] = obv.valueCodeableConcept.text
        observationData['type'] = 'Smoking'
        datedSmokingMeasurements[obv.effectiveDateTime + 'smoking'] = observationData;
      } else if (observationCodes.has("14959-1") || observationCodes.has("32994-1") || observationCodes.has("9318-7")) {
        //console.log(obv)
        // Microalbumin creatinine ratio
        const observationData = processObservationValue(obv)
        observationData['type'] = 'Microalbuminin Creatinine Ratio'
        observationData.order = 0.45;
        datedACRMeasurements[obv.effectiveDateTime + 'acr'] = observationData;
      } else if ((observationCodes.has("6298-4")) || (observationCodes.has("2823-3"))) {
        const observationData = processObservationValue(obv)
        observationData['type'] = 'Potassium'
        observationData.order = 0.2;
        datedSaltMeasurements[obv.effectiveDateTime + 'potassium'] = observationData;
      } else if ((observationCodes.has("2947-0")) || (observationCodes.has("2951-2"))) {
        const observationData = processObservationValue(obv)
        observationData.order = 0.1;
        observationData['type'] = 'Sodium'
        datedSaltMeasurements[obv.effectiveDateTime + 'sodium'] = observationData;
      } else if (observationCodes.has("10230-1")) {
        const observationData = processObservationValue(obv)
        datedLVEFMeasurements[obv.effectiveDateTime + 'lvef'] = observationData;
      //} else if (observationCode === "8716-3" || observationCode === "55284-4") {
      } else if (observationCodes.has("55284-4")) {
        //console.log(obv)
        obv.component.forEach(part => {
          // Blood pressure
          const code = client ? client.getPath(part, "code.coding.0.code") : part.code.coding[0].code;
          let observationData = null; 
          if (obv.effectiveDateTime in datedBPMeasurements) {
            observationData = datedBPMeasurements[obv.effectiveDateTime];
          } else {
            observationData = {};
            datedBPMeasurements[obv.effectiveDateTime] = observationData;
            observationData['date'] = obv.effectiveDateTime;
          }

          if (code === "8480-6") {
            observationData['systolic'] = Math.round(part.valueQuantity.value);
          } else if (code === "8462-4") {
            observationData['diastolic'] = Math.round(part.valueQuantity.value);
          }
        });
      } else {
        if (obv.valueQuantity)  {
          //codes.push(obv.code.coding)
          const commonItem = hasItemsInCommon(LAB_LOIN_CODES, observationCodes);
          if (commonItem) {
            //console.log("MIKE: started processing Other observation: ", obv)
            const observationData = processObservationValue(obv)
            observationData.order = getObservationOrder(commonItem)
            observationData['type'] = obv.code.text;
            datedOtherMeasurements[obv.effectiveDateTime + obv.code.text] = observationData;
          }
        }
      }  
      //console.log("MIKE: completed processing observation: ", obv, index)

    });
    console.log("Completed retrieving all observations");

    const datedLabMeasurements = {...datedSaltMeasurements, ...datedACRMeasurements, ...datedEGFRMeasurements, ...datedOtherMeasurements};
    const sortedLabMeasurements = [];
    Object.keys(datedLabMeasurements).sort().reverse().forEach(function(key) {
        sortedLabMeasurements.push(datedLabMeasurements[key])
    })

    const sortedBPMeasurements = [];
    Object.keys(datedBPMeasurements).sort().reverse().forEach(function(key) {
        sortedBPMeasurements.push(datedBPMeasurements[key])
    })

    const sortedEGFRMeasurements = [];
    Object.keys(datedEGFRMeasurements).sort().reverse().forEach(function(key) {
        sortedEGFRMeasurements.push(datedEGFRMeasurements[key])
    })

    const sortedACRMeasurements = [];
    Object.keys(datedACRMeasurements).sort().reverse().forEach(function(key) {
        sortedACRMeasurements.push(datedACRMeasurements[key])
    })

    const sortedLVEFMeasurements = [];
    Object.keys(datedLVEFMeasurements).sort().reverse().forEach(function(key) {
        sortedLVEFMeasurements.push(datedLVEFMeasurements[key])
    })

    const sortedSaltMeasurements = [];
    Object.keys(datedSaltMeasurements).sort().reverse().forEach(function(key) {
        sortedSaltMeasurements.push(datedSaltMeasurements[key])
    })

    const sortedOtherMeasurements = [];
    Object.keys(datedOtherMeasurements).sort().reverse().forEach(function(key) {
        sortedOtherMeasurements.push(datedOtherMeasurements[key])
    })

    const smokingStatus = datedSmokingMeasurements[Object.keys(datedSmokingMeasurements).sort().reverse()[0]]
    //console.log("Final smoking status is:", smokingStatus)

    const homeBPData = observations.homeBPData || [];
    console.log("About to process flow sheet observations");
    addFlowSheetDatatoHomeBPData(homeBPData, observations.flowSheetObservationData || [])
    console.log("Done processing flow sheet observations");

    //sortedBPMeasurements.shift() // MIKES, TAKE THIS LINE OUT! Just for testing
    console.log("Sorted BP Measurements are:", sortedBPMeasurements)
    const measurements = {bpMeasurements : sortedBPMeasurements,
            acrMeasurements : sortedACRMeasurements,
            lvefMeasurements : sortedLVEFMeasurements,
            saltMeasurements : sortedSaltMeasurements,
            labMeasurements : sortedLabMeasurements,
            egfrMeasurements : sortedEGFRMeasurements,
            otherMeasurements : sortedOtherMeasurements,
            homeBpMeasurements : homeBPData,
            smokingStatus: smokingStatus ? smokingStatus.value : "Unknown"};

    return measurements;
  }

  const addFlowSheetDatatoHomeBPData = (homeBPData, flowSheetObservationData) => {
    const timestampedMeasurements = {}
    //console.log("FlowSheet data NOW is", flowSheetObservationData); 
    // Grab all systolics and diastolics
    flowSheetObservationData.forEach(obv => {
      if (obv.code && (obv.code.text === 'Diastolic Blood Pressure' || obv.code.text === 'Systolic Blood Pressure')) {
        if (!(obv.effectiveDateTime in timestampedMeasurements)) {
          timestampedMeasurements[obv.effectiveDateTime] = {}
        }
        if (obv.code.text === 'Systolic Blood Pressure') {
          timestampedMeasurements[obv.effectiveDateTime].systolic = Math.round(obv.valueQuantity.value);
        } else if (obv.code.text === 'Diastolic Blood Pressure') {
          timestampedMeasurements[obv.effectiveDateTime].diastolic = Math.round(obv.valueQuantity.value);
        }
      }
    });

    const datedMeasurements = {};
    Object.keys(timestampedMeasurements).forEach(timestamp => {
      const obvDate = dayjs(timestamp).format('YYYY-MM-DD');
      const measurement = timestampedMeasurements[timestamp];
      if (!(obvDate in datedMeasurements)) {
        datedMeasurements[obvDate] = {date: obvDate, measurements: []};
      }
      measurement.date = obvDate;
      datedMeasurements[obvDate].measurements.push(measurement)
    })

    //console.log("MIKE, datedHomeMeasurements are", datedMeasurements)
    // Ignore time. Only use dates for keys;
    const sortedMeasurements = [];
    Object.keys(datedMeasurements).sort().reverse().forEach(function(key) {
        sortedMeasurements.push(datedMeasurements[key])
    })
    homeBPData.push(...sortedMeasurements)
  }

  const addBPsFromQuery = (measurements, querySbp, queryDbp) =>{
    const numEntries = Math.max(querySbp.length, queryDbp.length)
    const startDate = new Date().getTime()
    const pointList = []
    for (let i = 0; i < numEntries; i++) {
      const measurementDate = new Date(startDate - MILLIS_IN_A_DAY * i * 2); // Every other day
      const point = { 
        date: measurementDate.toISOString(),
        systolic: querySbp[i],
        diastolic: queryDbp[i]
      }
      console.log("Adding point")
      console.log(point);
      pointList.push(point);
    }
    measurements.bpMeasurements.unshift(...pointList);

    console.log("Now bp measurements are:");
    console.log(measurements.bpMeasurements);
  }
        
  const extractMedicationsFromQuery = (queryMeds, queryTabs) => {
    console.log("Rewriting meds!!")
    const medications = []
    queryMeds.forEach(med => {
       const medObj = {}
       medObj['authoredOn'] = '2019-08-02T12:12:12-04:00';
       medObj['displayName'] = med;
       medObj['quantity'] = queryTabs;
       medObj['status'] = 'active';
       medObj['dosageInstruction'] = {}
       medications.push(medObj)
    });
    return medications;
  }

  const extractAllergiesFromFHIR = (allergyIntolerances) => {
    console.log("Allergies are:")
    console.log(allergyIntolerances);
    const allergyList = [];
    allergyIntolerances.forEach(allergy => {
      if (allergy.code && allergy.code.text && allergy.category && allergy.category.includes("medication")) {
        allergyList.push(allergy.code.text)
      }
    });
    console.log("processed allergens")
    console.log(allergyList);
    return allergyList;
  }

  const getDosage = (instruction) => {
    const dosage = {};
     instruction.extension.forEach(extension => {
       if (extension.url === 'https://open.epic.com/fhir/extensions/ordered-dose' && extension.valueQuantity)
       {
         dosage.amount = extension.valueQuantity.value;
         dosage.unit = extension.valueQuantity.unit;
         console.log("set dosage to:", dosage)
       }
     });
     return dosage;
  }

  const parseQuantity = (text) => {
    const lowerText = text.toLowerCase();
    var qty = 1;
    var qtyRegex = /\b([^\s]+)\s+(tablet|pill|capsule)/
    const matches = lowerText.match(qtyRegex) 
    if (matches) {
      const tmpQty = matches[1]
      qty = eval(tmpQty)
    }
    console.log("Found qty in dosageInstruction", text, qty)
    return qty;
  }

  const parseTimesDaily = (text) => {
    let timesDaily = 1;
    const lowerText = text.toLowerCase();
    if ((lowerText.indexOf("twice daily") > -1 ) || 
      (lowerText.indexOf("twice a day") > -1 )) {
      timesDaily = 2;
    } else if (lowerText.indexOf(" times ") > 0) {
      // The below code is nice, but does not work on IE 11, hence the stupid string parsing
      /* 
      const matcher = lowerText.matchAll(/(\d+) times/g);
      for (const match of matcher) {
        timesDaily = match[1]
      }
      */

      // Grab all the words before the " times ", Find the last one that looks like a number
      const endIndex = lowerText.indexOf(" times ");
      const words = lowerText.slice(0, endIndex).split(" ")
      words.forEach(word => {
        if (!isNaN(word)) {
          timesDaily = parseInt(word)
        }
      })
    }
    //console.log("MIKE, Got this daily times amount", timesDaily)
    return timesDaily;
  }

  const extractMedicationsFromFHIR = (medications) => {
    console.log("Raw medications are:", medications)
    const medicationList = [];
    medications.filter(med => med.resourceType === "MedicationRequest").forEach(med => {
      const cleanedMedication = {status: 'inactive'}
      console.log("incoming med is", med)
      var foundStructuredQuantity = false
      var alternateQuantityFromText = 1;
      var alternateDosageUnit = null;
      cleanedMedication['timesDaily'] = 1;
      cleanedMedication['displayName'] = med.medicationCodeableConcept ? 
          med.medicationCodeableConcept.coding[0].display : med.medicationReference.display;
      console.log("Got a medication!", med, cleanedMedication['displayName']);
      if (med.dosageInstruction && med.dosageInstruction[0].doseAndRate) {

        if (med.dosageInstruction[0].text && med.dosageInstruction[0].text.indexOf("as needed") !== -1) {
          cleanedMedication['prn'] = true;
        }
        cleanedMedication['quantity'] = 1;
        med.dosageInstruction[0].doseAndRate.forEach(doseAndRate => {
          if (doseAndRate.doseQuantity) {
          //console.log("MIKE a doseAndRate is", doseAndRate);
          if (doseAndRate.doseQuantity.unit === "mg") {
            cleanedMedication['dosageAmount'] = doseAndRate.doseQuantity.value;
            cleanedMedication['dosageUnit'] = "mg";
          } else if (doseAndRate.doseQuantity && 
            (doseAndRate.doseQuantity.unit === "tablet" || doseAndRate.doseQuantity.unit === "capsule" || doseAndRate.doseQuantity.unit === "pill")) {
            cleanedMedication['quantity'] = doseAndRate.doseQuantity.value;
            foundStructuredQuantity = true;
          } 
          }
        })

        if (cleanedMedication["quantity"] && cleanedMedication["dosageAmount"]) {
          cleanedMedication['dosageAmount'] /= cleanedMedication["quantity"]
        }

      } else if (med.dosageInstruction && med.dosageInstruction[0].extension) {
        const dosage = getDosage(med.dosageInstruction[0])
        cleanedMedication['dosageAmount'] = dosage.amount;
        cleanedMedication['dosageUnit'] = dosage.unit;
      }

      if (med.dosageInstruction && med.dosageInstruction[0].text) {
        console.log("Got instructions")
        cleanedMedication['timesDaily'] = parseTimesDaily(med.dosageInstruction[0].text)
        alternateQuantityFromText = parseQuantity(med.dosageInstruction[0].text)
      }
      if (!foundStructuredQuantity) {
        cleanedMedication["quantity"] = alternateQuantityFromText;
        console.log("Overriding qty with", alternateQuantityFromText)
      }
      cleanedMedication['status'] = med.status;
      medicationList.push(cleanedMedication)
      // console.log(med)
      //console.log("MIKE, cleaned medication is", cleanedMedication)
    });
    console.log("Done cleaning all medications", medicationList);
    return medicationList;
  }

  const extractConditionsFromQuery = (queryConds) => {
    console.log("Rewriting conditions!!")
    const conditions = []
    queryConds.forEach(cond => {
       const condObj = {}
       condObj['recordedDate'] = '2019-08-02T12:12:12-04:00';
       condObj['name'] = cond;
       conditions.push(condObj)
    });
    return conditions;
  }

  const parseConditionCode = (codes) => {
    var conditionType = null;
    codes.forEach(code => {
      CKD_CODES.forEach(ckd_code => {
        if (code.code === ckd_code || code.code.startsWith(ckd_code + ".")) {
          console.log("COND: found CKD");
          conditionType = CKD;
        }
      });
      CHF_CODES.forEach(chf_code => {
        if (code.code === chf_code || code.code.startsWith(chf_code + ".")) {
          console.log("COND: found CHF");
          conditionType = CHF;
        }
      });
    })
    return conditionType;
  }

  const extractConditionsFromFHIR = (conditions) => {
    console.log(" Raw conditions are:", conditions)
    const conditionList = [];
     conditions.forEach(condition => {
       if (condition.code) {
        if (condition.code.text.toLowerCase().endsWith('pregnancy')) {
          console.log("Was pregnant")
          if (condition.clinicalStatus.coding && condition.clinicalStatus.coding[0].code === "resolved") {
            console.log("Resolved");
          } else {
            const relevantCondition = {}
            relevantCondition['recordedDate'] = condition['recordedDate']; 
            relevantCondition['name'] = condition.code.text;
            conditionList.push(relevantCondition)
          }
        } else if (condition.code.text.toLowerCase().indexOf('chronic kidney disease') !== -1) { 
          const relevantCondition = {}
          relevantCondition['recordedDate'] = condition['recordedDate']; 
          relevantCondition['name'] = condition.code.text;
          conditionList.push(relevantCondition)
        } else if (condition.code.text.toLowerCase().startsWith('myocardial')) { 
          const relevantCondition = {}
          relevantCondition['recordedDate'] = condition['recordedDate']; 
          relevantCondition['name'] = condition.code.text;
          conditionList.push(relevantCondition)
        } else if (condition.code.text.toLowerCase().startsWith('albuminuria')) { 
          const relevantCondition = {}
          relevantCondition['recordedDate'] = condition['recordedDate']; 
          relevantCondition['name'] = condition.code.text;
          conditionList.push(relevantCondition)
        } else if (condition.code.text.toLowerCase().startsWith('diabetes')) { 
          const relevantCondition = {}
          relevantCondition['recordedDate'] = condition['recordedDate']; 
          relevantCondition['name'] = condition.code.text;
          conditionList.push(relevantCondition)
        // } else if  (condition.code.text.toLowerCase().indexOf('congestive heart failure') !== -1) { 
        //   const relevantCondition = {}
        //   relevantCondition['recordedDate'] = condition['recordedDate']; 
        //   relevantCondition['name'] = condition.code.text;
        //   conditionList.push(relevantCondition)
        } else if (condition.code.coding) {
          const conditionType = parseConditionCode(condition.code.coding)
          if (conditionType) {
            const relevantCondition = {}
            relevantCondition['name'] = conditionType
            relevantCondition['recordedDate'] = condition['recordedDate']; 
            conditionList.push(relevantCondition)
          }
        }
       }
     });
     return conditionList;
  }

  const extractEGFRFromQuery = (queryEgfr) => {
    console.log("Rewriting egfr!!")
    const egfrs = []
    queryEgfr.forEach(egfr => {
       const egfrObj = {}
         egfrObj['recordedDate'] = '2019-08-02T12:12:12-04:00';
         egfrObj['value'] = parseInt(egfr);
         egfrs.push(egfrObj)
    });
    return egfrs;
  }

  const getPatient = (patientId, accessToken, env) => {
    var myHeaders = new Headers();
    myHeaders.append('pragma', 'no-cache');
    myHeaders.append('cache-control', 'no-cache');
    console.log("Gonna call /patient")
    //const url = `${LOCAL_PATIENT}?access_token=${accessToken}&patient=${patientId}&env=${env}&mrn=${patientMRN}`
    const url = `${LOCAL_PATIENT}?access_token=${accessToken}&patient=${patientId}&env=${env}`
    const promise = fetch(url, {
      cache: "no-store",
      method: 'GET'
    })
    .catch(error => console.error('Error:', error))
    .then(response => {
      return response.json()
    })
    .then((data) => {
      //console.log("Got Patient data. It be", data)
      return [data]; // List to make it look like the client call. #TODO remove this.
    });
    return promise;
  }

  const getMedications = (patientId, patientMRN, accessToken, env) => {
    console.log("In getMedications")
    const url = `${LOCAL_MEDICATION}?access_token=${accessToken}&patient=${patientId}&env=${env}&mrn=${patientMRN}&date=${getEarliestDataDate()}`
    const promise = fetch(url, {
      cache: "no-store",
      method: 'GET'
    })
    .catch(error => console.error('Error:', error))
    .then(response => response.json())
    .then((data) => {
      //console.log("Got Medication data. It be", data)
      return data;
    });
    return promise
  }

  const getConditions = (patientId, patientMRN, accessToken, env, encounter) => {
    const url = `${LOCAL_CONDITION}?access_token=${accessToken}&patient=${patientId}&env=${env}&mrn=${patientMRN}&date=${getEarliestDataDate()}&encounter=${encounter}`
    const promise = fetch(url, {
      cache: "no-store",
      method: 'GET'
    })
    .catch(error => console.error('Error:', error))
    .then(response => response.json())
    .then((data) => {
      //console.log("Got Condition data. It be", data)
      return data;
    });
    return promise
  }

  const getAllergies = (patientId, patientMRN, accessToken, env) => {
    const url = `${LOCAL_ALLERGY}?access_token=${accessToken}&patient=${patientId}&env=${env}&mrn=${patientMRN}&date=${getEarliestDataDate()}`
    const promise = fetch(url, {
      cache: "no-store",
      method: 'GET'
    })
    .catch(error => console.error('Error:', error))
    .then(response => response.json())
    .then((data) => {
      //console.log("Got Allergy data. It be", data)
      return data;
    });
    return promise
  }

  const getObservations = (patientId, patientMRN, accessToken, env) => {
    const url = `${LOCAL_OBSERVATION}?access_token=${accessToken}&patient=${patientId}&env=${env}&mrn=${patientMRN}&date=${getEarliestDataDate()}`
    const promise = fetch(url, {
      cache: "no-store",
      method: 'GET'
    })
    .catch(error => console.error('Error:', error))
    .then(response => response.json())
    .then((data) => {
      //console.log("Got Observation data. It be", data);
      return data;
    });
    return promise;
  }

  const getFHIRData = (patientId, accessToken, env, encounter, client, 
    updatePatientCallback, querySbp, queryDbp, queryAllergies, queryMeds, 
    queryTabs, queryConds, queryEgfr) => {
    console.log("Making FHIR calls for ", patientId)
    const start = new Date();
    const q = new URLSearchParams();
    q.set("code", [
      "http://loinc.org|55284-4", // BP, systolic & diastolic combined
      "http://loinc.org|33914-3", // Effective glomerular filtration rate
      "http://loinc.org|14959-1", // Microalbumin/Creatinine ratio
      "http://loinc.org|10230-1", // Left ventricular ejection fraction
      "http://loinc.org|2947-0",  // Sodium in blood
      "http://loinc.org|2951-2",  // Sodium in serum plasma
      "http://loinc.org|6298-4",  // Potassium in blood
      "http://loinc.org|2823-3",   // Potassium in serum/plasma
      "http://loinc.org|72166-2"   // Smoking status
    ].join(","));
    q.set("subject", patientId);
    console.log("Client is", client)
   
    /*
    const patientRequest = client ? 
      client.request(`/Patient?_id=${patientId}`, {
        pageLimit: 0,
        flat: true
      }) 
      : 
      getPatient(patientId, accessToken, env)

    patientRequest.then(patients => {
      console.log(`Fetching Patient FHIR Data took: ${new Date() - start}`);

      const patientMRN = getPatientMRN(patients[0])
      */

    Promise.all([
  
      client ?  client.request(`/Patient?_id=${patientId}`, {
        pageLimit: 0,
        flat: true
      }) : getPatient(patientId, accessToken, env),

      client ? client.request(`/AllergyIntolerance?patient=${patientId}`, {
        pageLimit: 0,
        flat: true
      }) : getAllergies(patientId, patientId, accessToken, env),

      client ? client.request(`/Condition?patient=${patientId}`, {
        pageLimit: 0,
        flat: true
      }) : getConditions(patientId, patientId, accessToken, env, encounter),

      client ? client.request(`/MedicationRequest?patient=${patientId}`, {
        pageLimit: 0,
        //resolveReferences: ["medicationReference", "encounter", "requester", "reasonReference", "dosage"],
        flat: true
      }) : getMedications(patientId, patientId, accessToken, env),

      
      client ? client.request(`Observation?${q}`, {
        pageLimit: 0,
        resolveReferences: ["performer"],
        flat: true
      }) : getObservations(patientId, patientId, accessToken, env)
      
    ]).then(([patientObj, allergyIntolerances, conditions, medications, observations]) => {
      console.log(`Fetching FHIR Data took: ${new Date() - start}`);
      console.log("Mike, Patient is:", patientObj);
      const { patient, goal } = patientObj[0]
      console.log("Patient is", patient);
      const measurements = extractMeasurementsFromObservations(client, observations)
      let allergies;

      if (querySbp || queryDbp) {
        addBPsFromQuery(measurements, querySbp || [], queryDbp || [])
      } 

      if (queryAllergies) {
        allergies = queryAllergies
      } else {
        allergies = extractAllergiesFromFHIR(allergyIntolerances)
      }

      if (queryMeds) {
        medications = extractMedicationsFromQuery(queryMeds, queryTabs)
      } else {
        medications = extractMedicationsFromFHIR(medications)
      }

      if (queryConds) {
        conditions = extractConditionsFromQuery(queryConds)
      } else {
        conditions = extractConditionsFromFHIR(conditions)
      }

      if (queryEgfr) {
        measurements.egfrMeasurements = extractEGFRFromQuery(queryEgfr)
      }

      if (hasTooHighACR(measurements.acrMeasurements)) {
        const condObj = {}
        condObj['recordedDate'] = '2019-08-02T12:12:12-04:00';
        condObj['name'] = "Albuminuria";
        conditions.push(condObj)
      }

      const patientMRN = getPatientMRN(patient)
      console.log(`Fetching & Parsing FHIR Data took: ${new Date() - start}`);
      updatePatientCallback(patient, patientMRN, conditions, medications, measurements,
        allergies, goal)
    }).catch((error) => {
    console.log(error);
    });
  };
  
  const getPatientMRN = (patient => {
    console.log("MIKE, getting MRN from ", patient)
    let mrn = null;
    patient.identifier.forEach(identifier => {
      if (identifier.type && identifier.type.text === "MRN") {
        mrn = identifier.value;
      }
    })
    return mrn;
  });


  export { getFHIRData, extractMedicationsFromFHIR };