import React, { useState } from "react";
import './BPChart.scss';
import {
  LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, 
  ResponsiveContainer
} from "recharts"

import { sortMeasurements, mean } from './shared/utils.js'
var dayjs = require('dayjs')

const MILLIS_IN_A_DAY = 86400000;
const DAYS_IN_YEAR = 365;
const DAYS_IN_QUARTER = 91;
const DAYS_IN_WEEK = 7;
const DBP_COLOR = "#ffb2b2"
const SBP_COLOR = "#8e0000"

export default function BPChart({measurements, homeMeasurements, numBPDates,
  chartDuration, clinicGoalSBP, clinicGoalDBP, showClinicData, showHomeData,
  forBPA}) {
  console.log("DOT INPUT DATA", measurements)

  const sortedHomeBPData = sortMeasurements(homeMeasurements);

  const chartBPData = (measurements, homeMeasurements, chartDuration, includeClinic, includeHome, mostRecentDate) => {
    //console.log(measurements[measurements.length-1]);
    const oldestAllowedDate = mostRecentDate - (chartDuration * MILLIS_IN_A_DAY); 
    //console.log("BPA: Oldest allowed date is:", oldestAllowedDate);
    //console.log("BPA", new Date(oldestAllowedDate).toLocaleDateString());

    const allData = [];
    var data = []

    if (includeClinic) {
      data = measurements.reduce(function(filtered, measurement) {
        var point = {date: dayjs(measurement.date).valueOf(),
                    systolic: measurement.systolic,
                    diastolic: measurement.diastolic,
                    systolic_goal: clinicGoalSBP,
                    diastolic_goal: clinicGoalDBP
                  };
        if (point.date > oldestAllowedDate) {
          filtered.push(point);
        }
        return filtered;
      }, []);
      var startPoint = {
        date: dayjs(mostRecentDate).valueOf(),
        systolic_goal: clinicGoalSBP,
        diastolic_goal: clinicGoalDBP
      };
      var endPoint = {
        date: dayjs(oldestAllowedDate).valueOf(),
        systolic_goal: clinicGoalSBP,
        diastolic_goal: clinicGoalDBP
      };
      data.push(startPoint)
      data.push(endPoint)
      allData.push(...data);
      console.log("Alldata now is:", allData);
    }

    if (includeHome) {
      data = homeMeasurements.reduce(function(filtered, measurement) {

        const systolic = mean(measurement.measurements.map(dailyMeasurement => dailyMeasurement.systolic))
        const diastolic = mean(measurement.measurements.map(dailyMeasurement => dailyMeasurement.diastolic))

        var point = {date: dayjs(measurement.date).valueOf(),
                    home_systolic: systolic,
                    home_diastolic: diastolic};
        if (point.date > oldestAllowedDate) {
          //console.log("And it's not too old!");
          filtered.push(point);
        }
        return filtered;
      }, []);
      allData.push(...data);
      console.log("Alldata for home now is:")
      console.log(allData)
    }

    return allData;
  }

  const getTicks = (mostRecentDate, chartDuration) => {
    const earliestDate = mostRecentDate - (chartDuration * MILLIS_IN_A_DAY)
    var interval;
    switch(chartDuration) {
      case DAYS_IN_YEAR:
        interval = 30.5 * MILLIS_IN_A_DAY; // Monthly
        break;
      case DAYS_IN_QUARTER:
        interval = 7 * MILLIS_IN_A_DAY; // Weekly
        break;
      case DAYS_IN_WEEK:
        interval = MILLIS_IN_A_DAY; // Daily
        break;
      default:
        interval = 30.5 * MILLIS_IN_A_DAY; // Monthly
        break;
    }

    const ticks = []
    while (mostRecentDate > earliestDate) {
      ticks.unshift(mostRecentDate - interval)
      mostRecentDate -= interval;
    }
    return ticks;

  }
  
  if (measurements.length === 0 && homeMeasurements.length === 0) {
    return (
      <div id="BP">
        <p>No Blood Pressure data available</p>
      </div>
    )
  }
  var mostRecentDate = dayjs().valueOf();

  let mostRecentHomeDate = null;
  let mostRecentClinicDate;
  if (measurements.length > 0) {
    mostRecentClinicDate = dayjs(measurements[0].date).valueOf()
  }

  if (showClinicData && showHomeData && mostRecentHomeDate) {
    mostRecentDate = (mostRecentClinicDate > mostRecentHomeDate) ? mostRecentClinicDate : mostRecentHomeDate;
  } else if (showHomeData && mostRecentHomeDate) {
    mostRecentDate = mostRecentHomeDate;
  }  else {
    mostRecentDate = mostRecentClinicDate;
  }

  const chartData = chartBPData(measurements.slice(0, numBPDates), sortedHomeBPData.slice(0, numBPDates), 
    chartDuration, showClinicData, showHomeData, mostRecentDate)
  const ticks = getTicks(mostRecentDate, chartDuration);

  // range of values for X axis. Give a little room on the right for dots to fit.
  const xDomain = [mostRecentDate - chartDuration * MILLIS_IN_A_DAY,
                  mostRecentDate + (2*MILLIS_IN_A_DAY)];
  let maxValue = 0;
  measurements.slice(0, numBPDates).forEach(measurement => {
    maxValue = maxValue > measurement.systolic ? maxValue : measurement.systolic
  })

  // range of values for Y axis. 
  const yDomain = [0, maxValue + 20]

  // console.log("BPA: chartData is", chartData)
  // console.log("BPA: ticks are", ticks)

  const CustomizedDot = (props) => {
    const { cx, cy, value } = props;
    if (!value)
      return null

    // Only use the orange triange out-of-goal dots for the BPA graph
    if (props.dataKey === "systolic") {
      //console.log("sysstolic props and value are", props, value)
      if (forBPA && value > clinicGoalSBP) {
        return (
          <svg x={cx - 13} y={cy - 15}  height={100} width={100} fill="orange" viewBox="0 0 1024 1024"> 
            <path d='M150 0 L70 160 L230 160 z' />
          </svg>
        )
      } else {
        return (
          <svg width="1000" height="1000">
            <g>
              <circle cx={cx} cy={cy} r="4" stroke={SBP_COLOR} fill="transparent"
              strokeWidth="3"></circle>
            </g> 
          </svg>
        )
      }
    } else if (props.dataKey === "diastolic") {
      //console.log("diastolic props and value are", props, value)
      if (forBPA && value > clinicGoalDBP) {
        return (
          <svg x={cx - 13} y={cy - 15}  height={100} width={100} fill="orange" viewBox="0 0 1024 1024"> 
            <path d='M150 0 L70 160 L230 160 z' />
          </svg>
        )
      } else {
        return (
          <svg width="1000" height="1000">
            <g>
              <circle cx={cx} cy={cy} r="4" stroke={DBP_COLOR} fill="transparent"
              strokeWidth="2"></circle>
            </g> 
          </svg>
        )
      }
    } 
  };

  return (
    <div className="BPChart">
      <ResponsiveContainer width="100%" height="100%">
        <LineChart width={580} height={300} data={chartData}>
          {showClinicData &&
            <Line type="monotone" name = "Clinic SBP" dataKey="systolic" stroke={SBP_COLOR} strokeWidth={2} dot={<CustomizedDot />}/>
          }
          {showClinicData &&
            <Line type="monotone" name = "Clinic DBP" dataKey="diastolic" stroke={DBP_COLOR} strokeWidth={2} dot={<CustomizedDot />}/>
          }
          {showClinicData &&
            <Line type="monotone" strokeDasharray="3 3" name = "Clinic SBP Goal" dataKey="systolic_goal" stroke="#808080" strokeWidth={2} dot={null}/>
          }
          {showClinicData &&
            <Line type="monotone" strokeDasharray="3 3" name = "Clinic DBP Goal" dataKey="diastolic_goal" stroke="#dcdcdc" strokeWidth={2} dot={null}/>
          }
          {showHomeData &&
            <Line type="monotone" name = "Home SBP" dataKey="home_systolic" stroke="#171b64" strokeWidth={2} />
          }
          {showHomeData &&
            <Line type="monotone" name = "Home DBP" dataKey="home_diastolic" stroke="#9ec2e1" strokeWidth={2} />
          }
          <Tooltip labelFormatter={t => new Date(t).toLocaleDateString()} />
          <Legend />
          <CartesianGrid stroke="#ccc" />
          <XAxis dataKey="date" type="number" tickFormatter={t => new Date(t).toLocaleDateString()} ticks={ticks} domain={xDomain} />
          <YAxis domain={yDomain}/>
        </LineChart>
      </ResponsiveContainer>
    </div>
  )
}