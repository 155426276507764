import React, { useState } from "react";
import './Expander.scss';

export function Expander({title, closedTitle, subcontent, children, isClosed}) {

  const [ open, setOpen ] = useState(!(isClosed === true))
  const expanderType = subcontent ? "subtitle" : "maintitle";

  return (
    <div className="Expander">
      <div className={expanderType}>
        <div className="title-bar" onClick={ () => setOpen(!open)}>
          { open ?
            <h4 className="title">{title}</h4>
          :
            <h4 className="title">{closedTitle || title}</h4>
          }
          { open ?
            <h4 className="caret">∧</h4>
          :
          <h4 className="caret">∨</h4>
          }
        </div>
        { open &&
          <div className="open">
            <div className="content">
              {children}
            </div>
          </div>
        }
      </div>
    </div>
  );
}