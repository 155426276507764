import React from "react";
import './BPActivateReport.scss';
import BPACheckbox from './BPACheckbox';
import BPChart from "./BPChart";
import { patientName, bpaDate, capitalizeFirst, clinicBPStats } from './shared/utils.js'
import { DEFAULT_DIASTOLIC_GOAL, DEFAULT_SYSTOLIC_GOAL } from './shared/constants';

import orangeTriangle from './shared/orange-triangle.png'
const DAYS_IN_YEAR = 365;

const NUM_BP_MEASUREMENTS_STATS = 2
const NUM_BP_MEASUREMENTS_CHART = 5

function BPActivateReport({patient, measurements, recommendations, hint }) {
  const clinicStats = clinicBPStats(measurements.bpMeasurements.slice(0, NUM_BP_MEASUREMENTS_STATS));
  console.log("BPA measurements are:", measurements)
  console.log("BPA patient is:", patient)
  console.log("BPA recommendations are:", recommendations)

  const medList = (meds) => {
    console.log("MIKE: Meds are", meds)
    return meds.map((medication, i) => {
      const pluralizedOrNot = medication.quantity === 1 ? "tab daily" : "tabs daily"
      const tabletStr = `${medication.quantity} ${pluralizedOrNot}`
      let name;
      let strength;
      if (medication.medications && medication.medications.length > 1) {
        name = `${capitalizeFirst(medication.medications[0].name)} / ${capitalizeFirst(medication.medications[1].name)}`;
        strength = `${medication.medications[0].dosageAmount} ${medication.medications[0].dosageUnit}/${medication.medications[1].dosageAmount} ${medication.medications[1].dosageUnit}`;
      } else {
        name = medication.displayName;
        strength = `${medication.dosageAmount} ${medication.dosageUnit}`;
      }
      return (
        <tr key={i}>
          <td>{name}</td>
          <td>{strength}</td>
          <td>{tabletStr}</td>
        </tr>
      )
    });
  }
  
  const optionsForRec = (options) => {
    return options.map((option, i) => {
      const text = option.name ? `${option.name} ${option.amount} ${option.unit}` : option
      return (
        <li key={i}>
          <span>{text}</span>
        </li>
      )
    });

  }

  const recList = (recs) => {
    return recs.map((rec, i) => {
      const text = rec.hint || rec.main;
      // Skip the options if there aren't any or if we have a top-level rec hint
      const needsOptions = rec.options.length > 0 && !rec.hint
      return (
        <li key={i}>
          <span>{text}</span>
          {needsOptions &&
            <ol>
              {optionsForRec(rec.options)}
            </ol>
          }
        </li>
      )
    });
  }

  const meds = medList(recommendations.medications)
  const recs = recList(recommendations.recommendations)

  return (
    <div className="BPActivateReport">
      <div className="report-name">
        <div className="adjacent">
          <h1 className="">BP Activate Report</h1>
          <p className="left-spaced">{bpaDate()}</p>
        </div>
        <p className="">Bring this report to your next appointment to discuss with your provider.</p>
      </div>
      <div className="report-patient">
        <h2>{patientName(patient.name)}</h2>
        <div className="adjacent">
          <h3 className="">DOB:</h3>
          <p className="">{patient.birthDate}</p> 
        </div>
      </div>

      <div className="bp-header">
        <h2>Your Blood Pressure</h2>
      </div>
      <div className="bp-chart-and-title">
        <h3>Your BP Measurements Over Time</h3>
        <BPChart 
          measurements={measurements.bpMeasurements}
          homeMeasurements={measurements.homeBpMeasurements}
          numBPDates={NUM_BP_MEASUREMENTS_CHART}
          chartDuration={DAYS_IN_YEAR}
          clinicGoalSBP={DEFAULT_SYSTOLIC_GOAL}
          clinicGoalDBP={DEFAULT_DIASTOLIC_GOAL}
          showClinicData={true}
          showHomeData={measurements.homeBpMeasurements.length > 0}
          forBPA={true}
        />
      </div>

      <div className="bp-stats">
        <h3 className="top-spaced">Your Average Recent BP</h3>
        <div className="adjacent top-spaced">
          <h2 className="">{`${clinicStats.systolicMean}/${clinicStats.diastolicMean}`}</h2>
          <p className="left-spaced">mmHg</p>
        </div>
        <div className="adjacent">
          <img src={orangeTriangle} alt="orange triangle" />
          <p className="orange left-spaced">is too high*</p>
        </div>
        <h3 className="double-top-spaced">Do you have more BP measurements to add?</h3>
        <BPACheckbox label="Yes (see next page)" />
        <BPACheckbox label="No" />
      </div>

      <div className="med-header">
        <h2>Your Current BP Medications</h2>
      </div>
      <div className="med-list">
        <table>
          <thead>
          <tr>
            <th>Medication</th>
            <th>Strength</th>
            <th className="left-spaced">Currently Taking</th>
          </tr>
          </thead>
          <tbody>
            {meds}
          </tbody>
        </table>

      </div>
      <div className="med-confirmation">
        <h3 className="top-spaced">Are these medications correct?</h3>
        <BPACheckbox label="Yes" />
        <BPACheckbox label="No (please make edits to list)" />
        <BPACheckbox label="Not sure" />
      </div>

      <div className="recommendations-header section-top">
        <h2>BP Activate Medication Suggestions</h2>
        <p className="top-spaced">These are computer-generated medication recommendations that might improve your blood pressure. Please review these recommendations with your provider. Remember, it is not safe to make any changes without discussing with your provider.</p>
      </div>
      <div className="recommendations-list grey">
        <ol>
          {recs}
        </ol>
      </div>
      <div className="recommendations-notes">
        <p>*Your bp is higher than the standard goal of 140/90 commonly used among providers. Your provider may set a different goal for you.</p>
      </div>
      <div className="recommendations-next section-top">
        <h3 className="">Next steps</h3>
        <BPACheckbox label="Schedule an appointment to discuss with my provider" />
        <BPACheckbox label="I already have an appointment scheduled with my provider" />
      </div>
    </div>
  )
}

export default BPActivateReport;