import React, { useState } from "react";
import './BPGoal.scss';

export function BPGoal({handleBPGoal}) {

  const [ sbp, setSbp ] = useState(null);
  const [ dbp, setDbp ] = useState(null);

  return (
    <div id="BPGoal" onClick={(event) => event.stopPropagation()}> 
      <div className="radio-button-list section">
        <p>Select one</p>
        <div className="radio-button" onClick={() => handleBPGoal(140, 90)}>
          <input type="radio" name="option" id="2" />
          <label htmlFor="2" className="radio-label">
            <span className="radio">140/90 mmHg</span>
          </label>
        </div>
        <div className="radio-button" onClick={() => handleBPGoal(130, 80)}>
          <input type="radio" name="option" id="3" />
          <label htmlFor="3" className="radio-label">
            <span className="radio">130/80 mmHg</span>
          </label>
        </div>
      </div>
      <div className="add-new section">
        <p>Or add new</p>
        <div className="manual">
          <span>BP goal</span>
          <input type="number" value={sbp || ''} onChange={(event) => setSbp(event.target.value)} />
          <span>/</span>
          <input type="number" value={dbp || ''} onChange={(event) => setDbp(event.target.value)} />
        </div>
        <button onClick={() => handleBPGoal(sbp, dbp)}>Add</button>
      </div>
    </div>
  );
}