import React from "react";
import './BPACheckbox.scss';

export default function BPACheckbox({label}) {

  return (
    <div className="BPACheckbox">
      <div className="box" />
      <p>{label}</p>
    </div>
  );
}