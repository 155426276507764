
const mrn = "96820810"

const patient = {
    "resourceType": "Patient",
    "id": "e65mOMqNjvV00YiC9iT2eAw3",
    "extension": [
        {
            "extension": [
                {
                    "valueCoding": {
                        "system": "http://hl7.org/fhir/us/core/ValueSet/omb-race-category",
                        "code": "2028-9",
                        "display": "Asian"
                    },
                    "url": "ombCategory"
                },
                {
                    "valueCoding": {
                        "system": "http://hl7.org/fhir/us/core/ValueSet/omb-race-category",
                        "code": "2054-5",
                        "display": "Black or African American"
                    },
                    "url": "ombCategory"
                },
                {
                    "valueCoding": {
                        "system": "http://hl7.org/fhir/us/core/ValueSet/omb-race-category",
                        "code": "2106-3",
                        "display": "White"
                    },
                    "url": "ombCategory"
                },
                {
                    "valueString": "Multiple Races",
                    "url": "text"
                }
            ],
            "url": "http://hl7.org/fhir/us/core/StructureDefinition/us-core-race"
        },
        {
            "extension": [
                {
                    "valueCoding": {
                        "system": "http://hl7.org/fhir/us/core/ValueSet/omb-ethnicity-category",
                        "code": "2186-5",
                        "display": "Not Hispanic or Latino"
                    },
                    "url": "ombCategory"
                },
                {
                    "valueString": "Not Hispanic or Latino",
                    "url": "text"
                }
            ],
            "url": "http://hl7.org/fhir/us/core/StructureDefinition/us-core-ethnicity"
        },
        {
            "valueCodeableConcept": {
                "coding": [
                    {
                        "system": "urn:oid:1.2.840.114350.1.13.266.3.7.10.698084.130.657370.74219999258",
                        "code": "male",
                        "display": "male"
                    }
                ]
            },
            "url": "http://open.epic.com/FHIR/StructureDefinition/extension/legal-sex"
        },
        {
            "valueCodeableConcept": {
                "coding": [
                    {
                        "system": "http://hl7.org/fhir/gender-identity",
                        "code": "male",
                        "display": "male"
                    }
                ]
            },
            "url": "http://hl7.org/fhir/StructureDefinition/patient-genderIdentity"
        },
        {
            "valueCodeableConcept": {
                "coding": [
                    {
                        "system": "urn:oid:1.2.840.114350.1.13.266.3.7.10.698084.130.657370.74219999258",
                        "code": "male",
                        "display": "male"
                    }
                ]
            },
            "url": "http://open.epic.com/FHIR/StructureDefinition/extension/sex-for-clinical-use"
        },
        {
            "valueCode": "M",
            "url": "http://hl7.org/fhir/us/core/StructureDefinition/us-core-birthsex"
        }
    ],
    "identifier": [
        {
            "use": "usual",
            "type": {
                "text": "BCHOMRN"
            },
            "system": "urn:oid:1.2.840.114350.1.13.355.2.7.5.737384.14",
            "value": "1970668"
        },
        {
            "use": "usual",
            "type": {
                "text": "CEID"
            },
            "system": "urn:oid:1.2.840.114350.1.13.266.3.7.3.688884.100",
            "value": "UCAJ24419PXW6MM"
        },
        {
            "use": "usual",
            "type": {
                "text": "EPI"
            },
            "system": "urn:oid:1.2.840.114350.1.13.266.3.7.5.737384.0",
            "value": "E6"
        },
        {
            "use": "usual",
            "type": {
                "text": "EXTERNAL"
            },
            "system": "urn:oid:1.2.840.114350.1.13.266.3.7.2.698084",
            "value": "Z7"
        },
        {
            "use": "usual",
            "type": {
                "text": "FHIR"
            },
            "system": "http://open.epic.com/FHIR/StructureDefinition/patient-dstu2-fhir-id",
            "value": "ToKqljPN6a4Hek8oXSORCV.THbHVs9tGho8C5Dz4wAr8B"
        },
        {
            "use": "usual",
            "type": {
                "text": "FHIR STU3"
            },
            "system": "http://open.epic.com/FHIR/StructureDefinition/patient-fhir-id",
            "value": "e65mOMqNjvV00YiC9iT2eAw3"
        },
        {
            "use": "usual",
            "type": {
                "text": "INTERNAL"
            },
            "system": "urn:oid:1.2.840.114350.1.13.266.3.7.2.698084",
            "value": "        Z7"
        },
        {
            "use": "usual",
            "type": {
                "text": "MRN"
            },
            "system": "urn:oid:1.2.840.114350.1.13.266.2.7.5.737384.14",
            "value": "96820810"
        },
        {
            "use": "usual",
            "type": {
                "text": "MYCHARTLOGIN"
            },
            "system": "urn:oid:1.2.840.114350.1.13.266.3.7.3.878082.110",
            "value": "FRODO"
        },
        {
            "use": "usual",
            "type": {
                "text": "WPRINTERNAL"
            },
            "system": "urn:oid:1.2.840.114350.1.13.266.3.7.2.878082",
            "value": "1"
        }
    ],
    "active": true,
    "name": [
        {
            "use": "official",
            "text": "Frodo Baggins",
            "family": "Baggins",
            "given": [
                "Frodo"
            ]
        },
        {
            "use": "usual",
            "text": "Frodo Baggins",
            "family": "Baggins",
            "given": [
                "Frodo"
            ]
        }
    ],
    "telecom": [
        {
            "system": "phone",
            "value": "123-111-5548",
            "use": "home"
        },
        {
            "system": "phone",
            "value": "123-111-5548",
            "use": "mobile"
        },
        {
            "system": "email",
            "value": "jory.purvis@ucsf.edu"
        }
    ],
    "gender": "male",
    "birthDate": "1980-09-22",
    "deceasedBoolean": false,
    "address": [
        {
            "use": "home",
            "line": [
                "1E Scott Ave",
                "Second address line"
            ],
            "city": "Shire",
            "state": "CA",
            "postalCode": "90210",
            "country": "000"
        }
    ],
    "maritalStatus": {
        "text": "Single"
    },
    "contact": [
        {
            "relationship": [
                {
                    "coding": [
                        {
                            "system": "urn:oid:1.2.840.114350.1.13.266.3.7.4.827665.1000",
                            "code": "28",
                            "display": "Conservator"
                        }
                    ],
                    "text": "Conservator"
                }
            ],
            "name": {
                "use": "usual",
                "text": "Contact,Test"
            },
            "telecom": [
                {
                    "system": "phone",
                    "value": "231-541-2345",
                    "use": "mobile"
                },
                {
                    "system": "email",
                    "value": "test@email.com"
                }
            ]
        },
        {
            "relationship": [
                {
                    "coding": [
                        {
                            "system": "http://terminology.hl7.org/CodeSystem/v2-0131",
                            "code": "E",
                            "display": "Employer"
                        }
                    ]
                }
            ],
            "address": {
                "use": "work",
                "line": [
                    "701 GATEWAY BOULEVARD",
                    "SUITE 440"
                ],
                "city": "SOUTH SAN FRANCISCO",
                "district": "SAN MATEO",
                "state": "CA",
                "postalCode": "94080",
                "country": "000"
            },
            "organization": {
                "display": "Abbott"
            }
        }
    ],
    "communication": [
        {
            "language": {
                "coding": [
                    {
                        "system": "http://hl7.org/fhir/ValueSet/languages",
                        "code": "en",
                        "display": "English"
                    }
                ],
                "text": "English"
            },
            "preferred": true
        }
    ],
    "managingOrganization": {
        "reference": "https://dev-unified-api.ucsf.edu/clinical/apex/api/FHIR/STU3/Organization/ehyP1Xx0.GSw2bTkL2ouHzg3",
        "display": "UCSF SERVICE AREA"
    }
}

const medications = [
    {
        "status": "active",
        "timesDaily": 1,
        "displayName": "amLODIPine (NORVASC) tablet",
        "quantity": 0.5,
        "dosageAmount": 5
    }
]

const measurements = {
    "bpMeasurements": [
        {
            "date": "2022-08-26T20:54:00Z",
            "systolic": 160,
            "diastolic": 85
        },
        {
            "date": "2022-08-26T20:53:00Z",
            "systolic": 150,
            "diastolic": 90
        },
        {
            "date": "2022-05-06T21:32:00Z",
            "systolic": 136,
            "diastolic": 71
        },
        {
            "date": "2022-04-29T21:35:00Z",
            "systolic": 151,
            "diastolic": 61
        },
        {
            "date": "2022-04-29T21:20:00Z",
            "systolic": 134,
            "diastolic": 95 
        }
    ],
    "acrMeasurements": [],
    "lvefMeasurements": [],
    "saltMeasurements": [
        {
            "referenceRange": [
                {
                    "low": {
                        "value": 137,
                        "unit": "mmol/L",
                        "system": "http://unitsofmeasure.org",
                        "code": "mmol/L"
                    },
                    "high": {
                        "value": 147,
                        "unit": "mmol/L",
                        "system": "http://unitsofmeasure.org",
                        "code": "mmol/L"
                    },
                    "text": "137 - 147 mmol/L"
                }
            ],
            "date": "2022-05-06T07:00:00Z",
            "value": 139,
            "unit": "mmol/L",
            "order": 0.1,
            "type": "Sodium"
        },
        {
            "referenceRange": [
                {
                    "low": {
                        "value": 3.4,
                        "unit": "mmol/L",
                        "system": "http://unitsofmeasure.org",
                        "code": "mmol/L"
                    },
                    "high": {
                        "value": 5.3,
                        "unit": "mmol/L",
                        "system": "http://unitsofmeasure.org",
                        "code": "mmol/L"
                    },
                    "text": "3.4 - 5.3 mmol/L"
                }
            ],
            "date": "2022-05-06T07:00:00Z",
            "value": 4,
            "unit": "mmol/L",
            "order": 0.2,
            "type": "Potassium"
        }
    ],
    "labMeasurements": [
        {
            "referenceRange": [
                {
                    "low": {
                        "value": 137,
                        "unit": "mmol/L",
                        "system": "http://unitsofmeasure.org",
                        "code": "mmol/L"
                    },
                    "high": {
                        "value": 147,
                        "unit": "mmol/L",
                        "system": "http://unitsofmeasure.org",
                        "code": "mmol/L"
                    },
                    "text": "137 - 147 mmol/L"
                }
            ],
            "date": "2022-05-06T07:00:00Z",
            "value": 139,
            "unit": "mmol/L",
            "order": 0.1,
            "type": "Sodium"
        },
        {
            "referenceRange": [
                {
                    "low": {
                        "value": 3.4,
                        "unit": "mmol/L",
                        "system": "http://unitsofmeasure.org",
                        "code": "mmol/L"
                    },
                    "high": {
                        "value": 5.3,
                        "unit": "mmol/L",
                        "system": "http://unitsofmeasure.org",
                        "code": "mmol/L"
                    },
                    "text": "3.4 - 5.3 mmol/L"
                }
            ],
            "date": "2022-05-06T07:00:00Z",
            "value": 4,
            "unit": "mmol/L",
            "order": 0.2,
            "type": "Potassium"
        },
        {
            "referenceRange": [
                {
                    "low": {
                        "value": 59,
                        "unit": "mL/min",
                        "system": "http://unitsofmeasure.org",
                        "code": "mL/min"
                    },
                    "text": "59 mL/min"
                }
            ],
            "date": "2022-05-06T07:00:00Z",
            "value": 104,
            "unit": "mL/min",
            "order": 0.48,
            "type": "eGFR"
        },
        {
            "referenceRange": [
                {
                    "low": {
                        "value": 40
                    },
                    "high": {
                        "value": 160
                    },
                    "text": "40 - 160"
                }
            ],
            "date": "2022-05-06T07:00:00Z",
            "value": 73,
            "order": 0.52,
            "type": "Triglycerides, serum"
        },
        {
            "referenceRange": [
                {
                    "low": {
                        "value": 0.55,
                        "unit": "mg/dL",
                        "system": "http://unitsofmeasure.org",
                        "code": "mg/dL"
                    },
                    "high": {
                        "value": 1.02,
                        "unit": "mg/dL",
                        "system": "http://unitsofmeasure.org",
                        "code": "mg/dL"
                    },
                    "text": "0.55 - 1.02 mg/dL"
                }
            ],
            "date": "2022-05-06T07:00:00Z",
            "value": 0.8,
            "unit": "mg/dL",
            "order": 0.45,
            "type": "Creatinine"
        },
        {
            "referenceRange": [
                {
                    "low": {
                        "value": 0
                    },
                    "high": {
                        "value": 200
                    },
                    "text": "0 - 200"
                }
            ],
            "date": "2022-05-06T07:00:00Z",
            "value": 180,
            "order": 0.9,
            "type": "Cholesterol, Total"
        },
        {
            "date": "2022-05-06T07:00:00Z",
            "value": 75,
            "order": 0.9,
            "type": "Cholesterol, HDL"
        }
    ],
    "egfrMeasurements": [
        {
            "referenceRange": [
                {
                    "low": {
                        "value": 59,
                        "unit": "mL/min",
                        "system": "http://unitsofmeasure.org",
                        "code": "mL/min"
                    },
                    "text": "59 mL/min"
                }
            ],
            "date": "2022-05-06T07:00:00Z",
            "value": 104,
            "unit": "mL/min",
            "order": 0.48,
            "type": "eGFR"
        }
    ],
    "otherMeasurements": [
        {
            "referenceRange": [
                {
                    "low": {
                        "value": 40
                    },
                    "high": {
                        "value": 160
                    },
                    "text": "40 - 160"
                }
            ],
            "date": "2022-05-06T07:00:00Z",
            "value": 73,
            "order": 0.52,
            "type": "Triglycerides, serum"
        },
        {
            "referenceRange": [
                {
                    "low": {
                        "value": 0.55,
                        "unit": "mg/dL",
                        "system": "http://unitsofmeasure.org",
                        "code": "mg/dL"
                    },
                    "high": {
                        "value": 1.02,
                        "unit": "mg/dL",
                        "system": "http://unitsofmeasure.org",
                        "code": "mg/dL"
                    },
                    "text": "0.55 - 1.02 mg/dL"
                }
            ],
            "date": "2022-05-06T07:00:00Z",
            "value": 0.8,
            "unit": "mg/dL",
            "order": 0.45,
            "type": "Creatinine"
        },
        {
            "referenceRange": [
                {
                    "low": {
                        "value": 0
                    },
                    "high": {
                        "value": 200
                    },
                    "text": "0 - 200"
                }
            ],
            "date": "2022-05-06T07:00:00Z",
            "value": 180,
            "order": 0.9,
            "type": "Cholesterol, Total"
        },
        {
            "date": "2022-05-06T07:00:00Z",
            "value": 75,
            "order": 0.9,
            "type": "Cholesterol, HDL"
        }
    ],
    "homeBpMeasurements": [],
    "smokingStatus": "Never Assessed"
}

const allergies = [
    "FISH CONTAINING PRODUCTS"
]

const conditions = []

export {mrn, patient, conditions, measurements, medications, allergies}