import MED_RECOS from './data/med_recommendations.json';
import TOOLTIPS from './tooltips_text.json';
import { hasItemsInCommon, toTitleCase, homeBPStats, historyOfCHF, historyOfCKD, 
  formatBP, formatHomeBP, formatACR, age, medicationType, getSpc, getSpcStep, 
  hasTooHighACR, convertAllergyNames, applicableHomeBPMeasurements, 
  rationaleWithSpacing, convertMedTypeToSet, normalizeClinicBpMeasurements, 
getAGLKey, getHomeDPB, getHomeSPB } from './shared/utils.js'
import { DEFAULT_DIASTOLIC_GOAL, DEFAULT_SYSTOLIC_GOAL, DEFAULT_HOME_DIASTOLIC_GOAL, 
  DEFAULT_HOME_SYSTOLIC_GOAL, UNCONTROLLED_HYPERTENSION, ELEVATED_BP,
  MISSING_DATA, OK } from './shared/constants';

// Changeable by the algorithm
var SYSTOLIC_GOAL = DEFAULT_SYSTOLIC_GOAL; 
var DIASTOLIC_GOAL = DEFAULT_DIASTOLIC_GOAL; 
var HOME_SYSTOLIC_GOAL = DEFAULT_HOME_SYSTOLIC_GOAL; 
var HOME_DIASTOLIC_GOAL = DEFAULT_HOME_DIASTOLIC_GOAL; 

function initResult() {
  return {'recommendations': [],
          'diagnosis': null,
          'document': null,
          'preface': [],
          'tooltip': null,
          'followups': [],
          'reasons': []};
}

function analyzePatient(clinicGoalSBP, clinicGoalDBP, patient, conditions, medications, 
  homeBpMeasurements, averageSbp, averageDbp, tmpClinicBpMeasurements, egfrMeasurements, 
  lvefMeasurements, acrMeasurements, saltMeasurements, smokingStatus, allergies) {

  if (clinicGoalSBP) {
    SYSTOLIC_GOAL = clinicGoalSBP;
    HOME_SYSTOLIC_GOAL = getHomeSPB(clinicGoalSBP);
  }
  if (clinicGoalDBP) {
    DIASTOLIC_GOAL = clinicGoalDBP;
    HOME_DIASTOLIC_GOAL = getHomeDPB(clinicGoalDBP)
  }

  const clinicBpMeasurements = normalizeClinicBpMeasurements(tmpClinicBpMeasurements);

  const result = initResult();
  const homeStats = homeBPStats(applicableHomeBPMeasurements(homeBpMeasurements), averageSbp, averageDbp);
  //const homeStats = homeBPStats(homeBpMeasurements, averageSbp, averageDbp);

  const bpLastInGoal = bpInGoal(clinicBpMeasurements, 0);

  result.diagnosis = getHypertensionLevel(clinicBpMeasurements, homeStats, result)
  console.log("homeBPMeasurements are", homeBpMeasurements)
  console.log("homeStats are", homeStats)

  const avoidHeartIssues = hasCongestiveHeartFailure(conditions, lvefMeasurements, result);
  const kidneyIssuesPreferAceArb = hasKidneyIssuesPreferAceArb(conditions, egfrMeasurements, result);
  const tooLowEgfr = hasLowEGFR(egfrMeasurements, 30);
  const tooHighAcr = hasAlbuminuria(conditions, acrMeasurements, result);
  const pregnant = isPregnant(conditions, result);
  const myocardialInfarction = hasMyocardialInfarction(conditions, result);

  const allMeds = medicationsWithType(medications, result);
  const relevantMeds = allMeds.filter(med => med.type != null);
  const activeMeds = relevantMeds.filter(med => med.type != null);
  const otherMeds = allMeds.filter(med => med.type === null);

  hasDiabetes(conditions, result)
  hasAllergies(allergies, result);
  hasChronicKidneyDisease(conditions, result);

  console.log("Active meds are:", activeMeds);
  console.log("Relevant meds are:", relevantMeds);
  console.log("allMeds are:", allMeds);
  activeMeds.forEach(med => {
    const timesDaily = med.timesDaily || 1;
    const tablet_str = med.quantity * timesDaily > 1 ? "tablets" : "tablet"
    if (med.type && med.type.startsWith("combo")) {
      const dosages = med.medications.map((subMed) => {
        return `${subMed.dosageAmount} ${subMed.dosageUnit} `
      }).join(" / ");
      result.reasons.push(['Anti-hypertensive Medications', `Currently taking ${med.quantity * timesDaily} ${tablet_str} of ${med.displayName}, this dose: ${dosages}`])
    } else if (med.type) {
      if (timesDaily > 1) {
        result.reasons.push(['Anti-hypertensive Medications', `Currently taking ${med.displayName} ${med.dosageAmount} ${med.dosageUnit}, 1 tablet, ${timesDaily} times daily`])
      } else {
        result.reasons.push(['Anti-hypertensive Medications', `Currently taking ${med.displayName} ${med.dosageAmount} ${med.dosageUnit}, ${med.quantity} ${tablet_str} daily`])
      }
    }
  });

  result.medications = relevantMeds;
  result.otherMedications = otherMeds;

  const medicationClasses = new Set();
  var onSPC = false;
  activeMeds.forEach(med => {
    const medClass = (med.type.toLowerCase().endsWith('diuretic')) ? 'Diuretic' : med.type;
    if (medClass.startsWith("combo")) {
      onSPC = true;
      med.medications.forEach(spcMed => {
        const spcMedClass= (spcMed.type.toLowerCase().endsWith('diuretic')) ? 'Diuretic' : spcMed.type;
        medicationClasses.add(spcMedClass);
      })
    } else {
      medicationClasses.add(medClass);
    }
  });

  result.reasons.push(['Patient Information', `Smoker: ${smokingStatus}`])

  if (result.diagnosis === OK) {
    result.preface.push('Controlled hypertension.');
    if (clinicBpMeasurements.length === 0) {
      result.reasons.push(['BP Measurements', `No clinic blood pressure measurements available`])
    }
  } else if (bpLastInGoal == null) {
    result.diagnosis = MISSING_DATA;
    result.preface.push('Measure blood pressure and assess.');
    result.reasons.push(['BP Measurements', 'No blood pressure measurements available'])
  } else {
    if (result.diagnosis === ELEVATED_BP) {
      result.preface.push('Check home blood pressure and reassess clinic blood pressure in 2 weeks');
    } 
  }
  
  if (onSPC) {
    recommendForSPCPatient(patient, 
                            conditions, 
                            activeMeds, 
                            clinicBpMeasurements, 
                            egfrMeasurements, 
                            acrMeasurements,
                            kidneyIssuesPreferAceArb,
                            tooHighAcr,
                            myocardialInfarction,
                            medicationClasses,
                            result);
                          
      if (medicationClasses.size > 2) {
        recommendForNTherapyPatient(patient, 
                                    conditions, 
                                    activeMeds, 
                                    clinicBpMeasurements, 
                                    egfrMeasurements, 
                                    acrMeasurements,
                                    saltMeasurements,
                                    medicationClasses,
                                    kidneyIssuesPreferAceArb,
                                    tooHighAcr,
                                    myocardialInfarction,
                                    allergies,
                                    result);
      }
  } else {
    switch(medicationClasses.size) {
      case 0:
        console.log("No current meds");
        recommendForNoMedsPatient(patient, 
                                  conditions, 
                                  activeMeds, 
                                  clinicBpMeasurements, 
                                  homeBpMeasurements,
                                  egfrMeasurements, 
                                  acrMeasurements,
                                  kidneyIssuesPreferAceArb,
                                  tooHighAcr,
                                  myocardialInfarction,
                                  result,
                                  homeStats);
        break;
      case 1:
        console.log("Monotherapy");
        recommendForMonoTherapyPatient(patient, 
                                      conditions, 
                                      activeMeds, 
                                      clinicBpMeasurements, 
                                      egfrMeasurements, 
                                      acrMeasurements,
                                      kidneyIssuesPreferAceArb,
                                      tooHighAcr,
                                      myocardialInfarction,
                                      result);
        break;
      case 2:
        console.log("Duotherapy");
        recommendForDuoTherapyPatient(patient, 
                                      conditions, 
                                      activeMeds, 
                                      clinicBpMeasurements, 
                                      egfrMeasurements, 
                                      acrMeasurements,
                                      kidneyIssuesPreferAceArb,
                                      tooHighAcr,
                                      myocardialInfarction,
                                      result);
        break;
      default:
        console.log("More than two current meds");
        recommendForNTherapyPatient(patient, 
                                    conditions, 
                                    activeMeds, 
                                    clinicBpMeasurements, 
                                    egfrMeasurements, 
                                    acrMeasurements,
                                    saltMeasurements,
                                    medicationClasses,
                                    kidneyIssuesPreferAceArb,
                                    tooHighAcr,
                                    myocardialInfarction,
                                    allergies,
                                    result);
        break;
    }
  }
  result.followups.push("Schedule visit in 2-4 weeks and reassess.")
  if (tooHighAcr) {
    result.reasons.push(['Anti-hypertensive Medications', 'Patients with Albuminuria require ACE or ARB'])
  } else if (myocardialInfarction) {
    result.reasons.push(['Anti-hypertensive Medications', 'Patients with Myocardial Infarction require ACE or ARB'])
  }

  if (age(patient) >= 70) {
    result.reasons.push(['Patient Information', `Patient (${age(patient)}) is older than 70`]);
  }

  if (avoidHeartIssues || tooLowEgfr || pregnant) {
    // Ignore previous recommendations
    console.log("Rewrite recommendations!")
    result.recommendations = [];
    result.preface = [];
    result.followups = [];

    if (avoidHeartIssues) {
      result.preface.push('This patient has congestive heart failure. Please refer to this document for treatment recommendations:');
      result.document = 'https://www.ncbi.nlm.nih.gov/pubmed/19379066';
    }
    if (tooLowEgfr) {
      result.preface.push(`This patient has an EGFR rate of ${Math.round(egfrMeasurements[0].value)}, which is below 30. Please refer to this document for treatment recommendations:`);
      result.document = 'https://www.ncbi.nlm.nih.gov/pubmed/29892833';
    }
    if (pregnant) {
      result.tooltip = rationaleWithSpacing(result.tooltip, TOOLTIPS.pregnant)
      result.preface.push('This patient is pregnant. Please refer to appropriate guidelines.');
    }
  }

  if (kidneyIssuesPreferAceArb) {
    result.tooltip = rationaleWithSpacing(result.tooltip, TOOLTIPS.possible_ckd)
  }

  if (tooHighAcr) {
    result.tooltip = rationaleWithSpacing(result.tooltip, TOOLTIPS.albuminuria)
  }

  if (myocardialInfarction) {
    result.tooltip = rationaleWithSpacing(result.tooltip, TOOLTIPS.myocardial_infarction)
  }

  if (betaBlockerMedications(activeMeds).length > 0) {
    result.preface.push('Note that this patient is on a beta-blocker, which affects the blood pressure but is considered a second-line medication');
  }

  reviewCurrentMedicationsForAllergies(activeMeds, allergies, result)
  filterRecommendationsForAllergies(allergies, result)

  if (result.recommendations.length === 0)
    result.tooltip = null;

  return result;
}

function reviewCurrentMedicationsForAllergies (relevantMedications, allergies, result) {
  const allergyMap = convertAllergyNames(allergies)
  //console.log("MIKE, allergyMap is", allergyMap);
  const allergyClassSet = new Set(Object.keys(allergyMap))
  relevantMedications.forEach(med => {
    const medClasses = new Set(convertMedTypeToSet(med.type));
    const commonItem = hasItemsInCommon(medClasses, allergyClassSet);
    if (commonItem) {
      //console.log(`MIKE warning for ${med.type} due to allergy to ${allergyMap[commonItem].toLowerCase()}`)
      result.preface.push(`This patient is allergic to ${allergyMap[commonItem]}. Consider stopping ${med.displayName} if noted allergy is confirmed before adding a new medication.`)
      result.recommendations = [];
    } 
  });
}

function filterRecommendationsForAllergies (allergies, result) {
  //console.log("MIKE, Allergies are", allergies);
  const filteredRecommendations = [] 
  const allergyMap = convertAllergyNames(allergies)
  const allergyClassSet = new Set(Object.keys(allergyMap))
  const applicableAllergySet = new Set()
  //console.log("MIKE, Allergy map is:", allergyMap)
  result.recommendations.forEach(reco => {
    //console.log("Processing allergies, handling this reco", reco);
    const commonItem = hasItemsInCommon(new Set(reco.medClasses), allergyClassSet);
    if (commonItem) {
      console.log(`Skipping recommendation for ${reco.medName} due to allergy to ${allergyMap[commonItem].toLowerCase()}`)
      applicableAllergySet.add(allergyMap[commonItem].toLowerCase());
    } else {
      filteredRecommendations.push(reco)
    }
  })
  if (applicableAllergySet.size > 0) {
    const allergyStr = Array.from(applicableAllergySet).join(", ").toLowerCase()
    result.reasons.push(['Anti-hypertensive Medications', `These recommendations accounted for this patient's known allergies to ${allergyStr}`])
  }
  result.recommendations = filteredRecommendations;
}

function lastPotassium(saltMeasurements) {
  var last = null;
  if (saltMeasurements.length > 0) {
    const kMeasurements = saltMeasurements.filter(measurement => measurement.type === "Potassium");
    last = kMeasurements[0].value
  }
  return last;
}

function hasMyocardialInfarction(conditions, result) {
  var myocardial = false;
  conditions.forEach(condition => {
    if (condition.name.toLowerCase().indexOf('myocardial') !== -1) {
      myocardial = true;
    }
  });
  if (myocardial)
    result.reasons.push(['Relevant Medical History', "Myocardial Infarction"]);
  else
    result.reasons.push(['Relevant Medical History', "No history of Myocardial Infarction"]);

  return myocardial;
}

function hasAlbuminuria(conditions, acrMeasurements, result) {
  var albuminuria = false;

  conditions.forEach(condition => {
    console.log("Checking for Albuminuria")
    if (condition.name.toLowerCase().indexOf('albuminuria') !== -1) {
      result.reasons.push(['Relevant Medical History', 'History of Albuminuria']);
      albuminuria = true;
    }
  });

  if (hasTooHighACR(acrMeasurements)) {
    if (acrMeasurements[0].unit === "mg/g") {
     result.reasons.push(['Relevant Medical History', `Microalbumin Creatinine Ratio ${formatACR(acrMeasurements, 0)} >= 30 mg/g`]);
    } else {
     result.reasons.push(['Relevant Medical History', `Microalbumin Creatinine Ratio ${formatACR(acrMeasurements, 0)} >= 3.4 mg/mmol`]);
    }
    albuminuria = true;
  }

  return albuminuria;
}

function lastMeasurementValue(measurements) {
  var value = null;
  if (measurements && measurements.length > 0) {
    value = measurements[0].value;
  }
  return value;
}

function hasDiabetes(conditions, result) {
  var has = false;
  conditions.forEach(condition => {
    if (condition.name.toLowerCase().indexOf('diabetes') >= 0)
      has = true;
  });
  if (has) 
    result.reasons.push(['Relevant Medical History', "History of Diabetes"])
}

function hasChronicKidneyDisease(conditions, result) {
  var has = false;
  if (historyOfCKD(conditions)) {
    result.reasons.push(['Relevant Medical History', "History of CKD"])
    has = true;
  } else
    result.reasons.push(['Relevant Medical History', "No history of CKD"])
  return has;
}

function hasCongestiveHeartFailure(conditions, lvefMeasurements, result) {
  var hasHeartIssues = false;
  const lvefValue = lastMeasurementValue(lvefMeasurements);
  if (lvefValue && lvefValue < 40) {
    console.log(`LVEF value is ${lvefValue}`);
    result.reasons.push(['Relevant Medical History', `LVEF (${Math.round(lvefMeasurements[0].value)}) is below 40%`]);
    hasHeartIssues = true;
  }

  if (historyOfCHF(conditions)) {
      result.reasons.push(['Relevant Medical History', `History of CHF`]);
      hasHeartIssues = true;
  } else
      result.reasons.push(['Relevant Medical History', `No history of CHF`]);

  return hasHeartIssues;
}

function egfrIndicatesLoopDiuretic(egfrMeasurements) {
  var lowEgfr = false;
  const lastEgfr = lastMeasurementValue(egfrMeasurements);
  if (lastEgfr && lastEgfr < 45) {
    console.log(`EGFR value is ${lastEgfr}`);
    lowEgfr = true
  }
  return lowEgfr;
}

function hasLowEGFR(egfrMeasurements, upperLimit) {
  var lowEgfr = false;
  const lastEgfr = lastMeasurementValue(egfrMeasurements);
  if (lastEgfr && lastEgfr < upperLimit) {
    lowEgfr = true
  }
  return lowEgfr;
}

function hasAllergies(allergies, result) {
  if (allergies) {
    const allergyStr = allergies.join(", ").toLowerCase()
    result.reasons.push(['Patient Information', "Allergies: " + allergyStr])
  }

  return allergies.length > 0;
}


function isPregnant(conditions, result) {
  var pregnant = false;
  conditions.forEach(condition => {
    if (condition.name.toLowerCase().indexOf('pregnancy') !== -1) {
      pregnant = true;
    }
  });
  if (pregnant)
    result.reasons.push(['Patient Information', "Pregnant: Yes"])
  else
    result.reasons.push(['Patient Information', "Pregnant: No"])
  return pregnant;
}


function hasKidneyIssuesPreferAceArb(conditions, egfrMeasurements, result) {
  var kidneyDisease = false;
  var acceptableEgfr = true;

  if (hasLowEGFR(egfrMeasurements, 30)) {
    //console.log("MIKE, EGFR is below 30!")
    result.reasons.push(['Relevant Medical History', `EGFR (${Math.round(egfrMeasurements[0].value)}) is below 30`]);
    acceptableEgfr = false;
  }

  if (hasLowEGFR(egfrMeasurements, 60)) {
      //console.log("MIKE, EGFR is below 60!")
      kidneyDisease = true;
      if (acceptableEgfr) {
        result.reasons.push(['Relevant Medical History', "ACE or ARB preferred due to EGFR"])
      }
  }
  return kidneyDisease && acceptableEgfr;
}

function applicableIntensificationSteps(nextSteps, patient, conditions, medications, clinicBpMeasurements, egfrMeasurements, acrMeasurements, kidneyIssuesPreferAceArb, tooHighAcr, myocardialInfarction, result) {
  return nextSteps.filter((step) => {
    if (step.applies) {
      return stepApplies(step, patient, conditions, medications, clinicBpMeasurements, egfrMeasurements, acrMeasurements, kidneyIssuesPreferAceArb, tooHighAcr, myocardialInfarction, result) 
    } else {
      return true;
    }
  });
}

function stepApplies(step, patient, conditions, medications, clinicBpMeasurements, egfrMeasurements, acrMeasurements, kidneyIssuesPreferAceArb, tooHighAcr, myocardialInfarction, result) {
  var applies = false;
  console.log(`Step type is: ${step.type}`)
  switch (step.applies.type) {
    case "condition":
      var hasCondition = false;
      conditions.forEach((condition) => {
        //console.log("Analyzing this condition")
        //console.log(condition);
        if (condition.name.toLowerCase() === step.applies.name.toLowerCase()) {
          //console.log("Found it!");
          hasCondition = true;
        }
      });
      applies = (step.applies.when === "has") === hasCondition
      break;

    default:
      //console.log(`In default case`);
      applies = true
  }
  return applies
}

function getSPCFromMedications(medications) {
  const spcs =  medications.filter(med => med.medications && med.medications.length > 1);
  const [first] = spcs
  return first
}

function recommendForSPCPatient(patient, conditions, medications, clinicBpMeasurements, egfrMeasurements, acrMeasurements, kidneyIssuesPreferAceArb, tooHighAcr, myocardialInfarction, medicationClasses, result) {
  // First let's identify where in the SPC chart the patient is
  console.log("In recommmendForSPC, medications are:", medications)
  const spcMed = getSPCFromMedications(medications);
  const spc = getSpc(spcMed);
  //console.log("SELA, spc is:", spc)
  if (spc) {
    const step = getSpcStep(spc, spcMed)
    console.log("SPC step is", step)
    if (step !== null) {
      console.log("Found the intensification", step);
      const nextStep = applicableIntensificationSteps(step.next_step, patient, conditions, medications, clinicBpMeasurements, egfrMeasurements, acrMeasurements, kidneyIssuesPreferAceArb, tooHighAcr, myocardialInfarction, result)[0]
      console.log("Next step is")
      console.log(nextStep);
      suggestNextSpcStep(spc, step, nextStep, medicationClasses, result)
    } else if (spc.recommended === false) {
      result.preface.push(spc.suggestion);
      result.documentHint = spc.suggestion;
    } else {
      result.reasons.push(['Anti-hypertensive Medications', "Patient currently on unrecognized or highest allowed dose of SPC."])
    }
  } else {
    result.reasons.push(['Anti-hypertensive Medications', "Patient currently on unrecognized SPC."])
  }
}

function suggestNextSpcStep(spc, currStep, nextStep, medicationClasses, result) {
  const unit = nextStep.unit || "mg";
  if (nextStep.change === "add medication") {
    if (medicationClasses.size > 2) {
      console.log("Will not add new medication since already on 3+ med classes");
    } else {
      result.reasons.push(['Anti-hypertensive Medications', "Will add medication rather than increase SPC dose"])
      switch (nextStep.medication) {
        case "tz":
          result.recommendations.push(TZRecommendation());
          break;
        case "ccb":
          result.recommendations.push(CCBRecommendation());
          break;
        case "ace":
          result.recommendations.push(ACERecommendation());
          break;
        default:
          console.log(`Unknown medication type: ${nextStep.medication}`);
      }
    }
  } else if (nextStep.change === "increase tabs") {
    const aglKey = getAGLKey(spc.name, nextStep.dosage, nextStep.unit, nextStep.tabs)
    const reco = { 
      main: `Increase number of tablets of ${spc.name.replace("(preferred)", "")} to ${nextStep.tabs} while maintaining dosage at ${currStep.dosage} ${unit}`,
      medClasses: nextStep.med_classes,
      aglKey: aglKey,
      show: 1,
      options: [],
    }
    result.recommendations.push(reco);
    result.documentHint = `Increase number of tablets of ${spc.name.replace("(preferred)", "")} to ${nextStep.tabs}`;
    result.reasons.push(['Anti-hypertensive Medications', "Will intensify SPC level"])
  } else if (nextStep.change === "increase dosage") {
    const tabletStr = nextStep.tabs === 1 ? "tablet" : "tablets"
    const aglKey = getAGLKey(spc.name, nextStep.dosage, nextStep.unit, nextStep.tabs)
    const reco = { 
      main: `Increase dosage of  ${spc.name.replace("(preferred)", "")} to ${nextStep.dosage} ${unit}, ${nextStep.tabs} ${tabletStr} per day`,
      medClasses: nextStep.med_classes,
      aglKey: aglKey,
      show: 1,
      options: [],
    }
    result.recommendations.push(reco);
    result.documentHint = `Increase dosage of  ${spc.name.replace("(preferred)", "")} to ${nextStep.dosage} ${unit}, ${nextStep.tabs} ${tabletStr} per day`;
    result.reasons.push(['Anti-hypertensive Medications', "Will intensify SPC level"])
  } else {
    console.log(`UNKNOWN INTENSIFICATION TYPE: ${nextStep.change}`);
  }
}

function recommendForNoMedsPatient(patient, conditions, medications, clinicBpMeasurements, 
  homeBPMeasurements, egfrMeasurements, acrMeasurements, 
  kidneyIssuesPreferAceArb, tooHighAcr, myocardialInfarction, result, homeStats) {

  const homeOutOfGoal = homeStats.systolicMean > HOME_SYSTOLIC_GOAL || homeStats.diastolicMean > HOME_DIASTOLIC_GOAL;

  result.reasons.push(['Anti-hypertensive Medications', "Currently on no hypertension medications"])
  if (clinicBpMeasurements.length > 0 && (clinicBpMeasurements[0].systolic >= 160 || clinicBpMeasurements[0].diastolic >= 100)) {
    result.reasons.push(['BP Measurements', `SBP (${Math.round(clinicBpMeasurements[0].systolic)}) >=160 or DBP  (${Math.round(clinicBpMeasurements[0].diastolic)}) >= 100`])
    result.recommendations.push(...beginSPC())
    result.tooltip = TOOLTIPS.why_spcs
    result.followups.push("Follow-up labs (BMP) are needed within 2 weeks if adding benazepril or HCTZ.")
  }
  else if (homeOutOfGoal) {
    result.recommendations.push(...beginSPC())
    result.followups.push("Follow-up labs (BMP) are needed within 2 weeks if adding benazepril or HCTZ.")
  } else  {
    result.reasons.push(['BP Measurements', `SBP (${Math.round(clinicBpMeasurements[0].systolic)}) < 160 and DBP (${Math.round(clinicBpMeasurements[0].diastolic)})  < 100`])

    if (kidneyIssuesPreferAceArb || tooHighAcr || myocardialInfarction) {
      result.recommendations.push(...beginSPC());
    } else {
      const systolicAmountAboveGoal = clinicBpMeasurements[0].systolic - SYSTOLIC_GOAL;
      const diastolicAmountAboveGoal = clinicBpMeasurements[0].diastolic - DIASTOLIC_GOAL;
      console.log("No extra conditions", systolicAmountAboveGoal, diastolicAmountAboveGoal)
      if ((systolicAmountAboveGoal <= 5) && (diastolicAmountAboveGoal <= 5)) {
        result.reasons.push(['BP Measurements', `CCB Monotherapy preferred if SBP (${Math.round(clinicBpMeasurements[0].systolic)}) and DBP (${Math.round(clinicBpMeasurements[0].diastolic)}) <= 5mmHg above goal`])
        result.recommendations.push(CCBRecommendation());
        result.tooltip = TOOLTIPS.ccb_vs_spc 
        result.recommendations.push(...beginSPC());
      } else {
        result.recommendations.push(...beginSPC());
        //result.recommendations.push(...beginSPC(TOOLTIPS.why_spcs));
        result.recommendations.push(CCBRecommendation());
        result.tooltip = TOOLTIPS.ccb_vs_spc 
        result.reasons.push(['BP Measurements', `SBP (${Math.round(clinicBpMeasurements[0].systolic)}) or DBP (${Math.round(clinicBpMeasurements[0].diastolic)}) > 5mmHg above goal`])
      }
    }
  }
}

function recommendForMonoTherapyPatient(patient, conditions, medications, clinicBpMeasurements, 
  egfrMeasurements, acrMeasurements, kidneyIssuesPreferAceArb, tooHighAcr, 
  myocardialInfarction, result) {
  result.reasons.push(['Anti-hypertensive Medications', "Currently on one hypertension medication class"])
  result.tooltip = TOOLTIPS.low_dose_without_ccb_increase;

  if (ccbMedications(medications).length > 0) {
    if (!kidneyIssuesPreferAceArb) {
      optimizeCurrentMedications(medications, kidneyIssuesPreferAceArb, tooHighAcr, myocardialInfarction, result);
      if (result.increasingCCB) {
        result.tooltip = TOOLTIPS.low_dose;
      }
    }
    result.reasons.push(['Anti-hypertensive Medications', "Patient is on CCB Monotherapy."]);
    result.recommendations.push(AceTzSPCRecommendation(true));

    result.followups.push("Follow-up labs are needed within 2 weeks if adding thiazide or ACE inhibitor.")
    result.recommendations.push(CcbAceSPCRecommendation());

    if (kidneyIssuesPreferAceArb) {
      optimizeCurrentMedications(medications, kidneyIssuesPreferAceArb, tooHighAcr, myocardialInfarction, result);
    }

    if (!tooHighAcr && !myocardialInfarction) {
      result.recommendations.push(TZRecommendation());
    }

  } else if (tzMedications(medications).length > 0) {
    result.reasons.push(['Anti-hypertensive Medications', "Patient is on thiazide monotherapy."]);
    result.recommendations.push(AceTzSPCRecommendation());

    if (kidneyIssuesPreferAceArb) {
      result.recommendations.push(ACERecommendation()); 
      optimizeCurrentMedications(medications, kidneyIssuesPreferAceArb, tooHighAcr, myocardialInfarction, result);
    } else {
      optimizeCurrentMedications(medications, kidneyIssuesPreferAceArb, tooHighAcr, myocardialInfarction, result);
      result.recommendations.push(ACERecommendation()); 
    }
    result.followups.push("Follow-up labs are needed within 2 weeks if adding an ACE inhibitor or ARB.")
  } else if (aceMedications(medications).length > 0) {
    result.recommendations.push(AceTzSPCRecommendation());
    result.recommendations.push(CcbAceSPCRecommendation());
    optimizeCurrentMedications(medications, kidneyIssuesPreferAceArb, tooHighAcr, myocardialInfarction, result);
    //if (!tooHighAcr && !myocardialInfarction) {
    result.recommendations.push(TZRecommendation());
    //}
    result.followups.push("Follow-up labs are needed within 2 weeks if adding chlorthalidone, hydrochlorothiazide or benazepril.")
    if (egfrIndicatesLoopDiuretic(egfrMeasurements)) {
      result.recommendations.push(`Consider using a loop diuretic instead of thiazide due to EGFR (${Math.round(egfrMeasurements[0].value)}) < 45 ml/min`)
    }
  } else if (arbMedications(medications).length > 0) {
    result.recommendations.push(ArbTzSPCRecommendation());
    optimizeCurrentMedications(medications, kidneyIssuesPreferAceArb, tooHighAcr, myocardialInfarction, result);
    result.reasons.push(['Anti-hypertensive Medications', "Patient is on ARB monotherapy"]);
    //if (!tooHighAcr && !myocardialInfarction) {
      result.recommendations.push(TZRecommendation());
    //}
    result.followups.push("Follow-up labs are needed within 2 weeks if adding chlorthalidone or hydrochlorothiazide.")
    if (egfrIndicatesLoopDiuretic(egfrMeasurements)) {
      result.preface.push(`Consider using a loop diuretic instead of thiazide due to EGFR (${Math.round(egfrMeasurements[0].value)}) < 45 ml/min`)
    }
  } else if (betaBlockerMedications(medications).length > 0) {
    result.tooltip += TOOLTIPS.bb_monotherapy;
    result.reasons.push(['Anti-hypertensive Medications', "Patient is on Beta blocker monotherapy"]);
    result.recommendations.push(AceTzSPCRecommendation());
    result.recommendations.push(CcbAceSPCRecommendation());
    result.recommendations.push(ArbTzSPCRecommendation());
    if (kidneyIssuesPreferAceArb) {
      result.recommendations.push(ACERecommendation());
      result.recommendations.push(ARBRecommendation());
    }
    optimizeCurrentMedications(medications, kidneyIssuesPreferAceArb, tooHighAcr, myocardialInfarction, result);
    if (!tooHighAcr && !myocardialInfarction) {
      result.recommendations.push(TZRecommendation());
    }

    if (!kidneyIssuesPreferAceArb)
      result.recommendations.push(ACERecommendation());

    if (!tooHighAcr && !myocardialInfarction) {
      result.recommendations.push(CCBRecommendation());
    }

    if (!kidneyIssuesPreferAceArb)
      result.recommendations.push(ARBRecommendation());

    if (egfrIndicatesLoopDiuretic(egfrMeasurements)) {
      result.preface.push(`Consider using a loop diuretic instead of thiazide due to EGFR (${Math.round(egfrMeasurements[0].value)}) < 45 ml/min`)
    }
  }
}

function convertMedClassToOptions(medClass) {
  return MED_RECOS.individual[medClass].map((med) => {
    return { name: toTitleCase(med.name),
             amount: med.initial_amount,
             unit: med.unit,
             aglKey: getAGLKey(med.name, med.initial_amount, med.unit, 1),
             preferred: med.preferred ? true : false         
    }
  });
}

function recommendForDuoTherapyPatient(patient, conditions, medications, clinicBpMeasurements, 
  egfrMeasurements, acrMeasurements, kidneyIssuesPreferAceArb, tooHighAcr, 
  myocardialInfarction, result) {
  result.reasons.push(['Anti-hypertensive Medications', "Currently on two hypertension medication classes"])
  var requiresAceOrArb = false;
  if (tooHighAcr || myocardialInfarction) {
    requiresAceOrArb = true;
  }

  addMissingMedication(medications, kidneyIssuesPreferAceArb, tooHighAcr, myocardialInfarction, requiresAceOrArb, result)
  optimizeCurrentMedications(medications, kidneyIssuesPreferAceArb, tooHighAcr, myocardialInfarction, result);
}

function recommendForNTherapyPatient(patient, conditions, medications, clinicBpMeasurements, 
                                     egfrMeasurements, acrMeasurements, saltMeasurements, 
                                     medicationClasses, kidneyIssuesPreferAceArb,
                                    tooHighAcr, myocardialInfarction, allergies, result) {
  const reason = ['Anti-hypertensive Medications', `Currently on ${medicationClasses.size} hypertension medication classes`];
  result.reasons.push(reason);
  result.tooltip = TOOLTIPS.multiple_meds;
  optimizeCurrentMedications(medications, kidneyIssuesPreferAceArb, tooHighAcr, myocardialInfarction, result);
  result.preface.push("Possible resistant hypertension. Check adherence and check for interfering agents before adding medications.");
  const k_measurement = lastPotassium(saltMeasurements);
  if (k_measurement > 4.5 || allergiesInclude(allergies, "spironolactone")) {
    if (betaBlockerMedications(medications).length > 0) {
      result.preface.push('This is a relatively complex patient with resistant hypertension taking a beta-blocker other than Labetalol. Recommend consulting with a primary care physician or hypertension specialist (e.g. cardiologist or nephrologist) for further management.');
    } else {
      if (nonAceOrArbMedicationIsOk(medications, tooHighAcr, myocardialInfarction)) {
        result.recommendations.push(LabetalolRecommendation());
      }
    }
  } else {
    if (nonAceOrArbMedicationIsOk(medications, tooHighAcr, myocardialInfarction)) {
      result.recommendations.push(SpironolactoneRecommendation());
    }
    result.followups.push("Follow-up labs (BMP) are needed within 2 weeks when adding spironolactone.")
  }
}

function allergiesInclude(allergies, allergen) {
  let include = false;
  allergies.forEach(allergy => {
    if (allergen.toLowerCase() === allergy.toLowerCase()) {
      include = true;
    }
  })
  return include;
}

function checkDosage(medication, result, useMaximum=false) {
  console.log("in checkDosage, medication is:", medication, useMaximum);
  let bestDosage = medication.optimalDosageAmount
  let optimalVsMaxmimalStr = "optimal"
  if (useMaximum && medication.maximumDosageAmount) {
    bestDosage = medication.maximumDosageAmount;
    optimalVsMaxmimalStr = "maximal"
  }

  const dosageBelowBest = medication.totalDosage < bestDosage
  const dosageAboveBest = medication.totalDosage > bestDosage

  if (dosageBelowBest) {
    if (medication.type === "CCB") {
      result.increasingCCB = true;
    }

    const recommendation = dosageChangeOption(medication, bestDosage);
    if (useMaximum) {
      // Indicates a patient with Albuminuria and this recommendation should be first
      result.recommendations.unshift(recommendation);

    } else {
      result.recommendations.push(recommendation);
    }
  } else if (dosageAboveBest) {
    result.reasons.push(['Anti-hypertensive Medications', `Patient's dose of ${medication.displayName} is above the ${optimalVsMaxmimalStr} dose of ${bestDosage} ${medication.dosageUnit}`]);
  } else if (bestDosage) {
    result.reasons.push(['Anti-hypertensive Medications', `Patient is already on ${optimalVsMaxmimalStr} dose of ${medication.displayName}`]);
  }
}

function calculateNewDosage(medication, bestDosage) {
  let newDosage = bestDosage;
  if (medication.increment) {
    const timesDaily = medication.timesDaily || 1;
    newDosage = medication.increment * timesDaily + medication.totalDosage;
  }
  return Math.min(newDosage, bestDosage)
}

function dosageChangeOption(medication, bestDosage) {
  console.log("**** In dosageChangeOption", medication, bestDosage)
  let newDosage = calculateNewDosage(medication, bestDosage)
  let addendum = '';
  let timesDaily = medication.timesDaily;
  if (medication.timesDaily > 1) {
    newDosage = newDosage / medication.timesDaily;
    addendum = `, ${medication.timesDaily} times daily`;
  } else if (medication.optimalTimesDaily && medication.optimalTimesDaily > 1) {
    timesDaily = medication.optimalTimesDaily;
    newDosage = newDosage / medication.optimalTimesDaily;
    addendum = `, ${medication.optimalTimesDaily} times daily`;
  }

  let optimalString = '';
  if (newDosage === medication.optimalDosageAmount) {
    optimalString = 'optimal dose of '
  } else if (newDosage === medication.maximumDosageAmount) {
    optimalString = 'maximal dose of '
  }

  return { 
           main: "",
           hint:`Increase dosage of ${toTitleCase(medication.displayName)} to ${newDosage} ${medication.dosageUnit}`,
           aglKey: getAGLKey(medication.displayName, newDosage, medication.dosageUnit, timesDaily),
           medClasses: [medication.type.toLowerCase()],
           options: [`Increase dosage of ${toTitleCase(medication.displayName)} to ${optimalString}${newDosage} ${medication.dosageUnit}${addendum}`]
         };
}

function optimizeCurrentMedications(medications, kidneyIssuesPreferAceArb, tooHighAcr, myocardialInfarction, result) {
  if (nonAceOrArbMedicationIsOk(medications, tooHighAcr, myocardialInfarction)) {
    ccbMedications(medications).forEach(medication => {
      checkDosage(medication, result)
    });
  }

  if (nonAceOrArbMedicationIsOk(medications, tooHighAcr, myocardialInfarction)) {
    tzMedications(medications).forEach(medication => {
      checkDosage(medication, result)
    });
  }

  aceMedications(medications).forEach(medication => {
    checkDosage(medication, result, tooHighAcr)
  });

  arbMedications(medications).forEach(medication => {
    checkDosage(medication, result, tooHighAcr)
  });

  if (nonAceOrArbMedicationIsOk(medications, tooHighAcr, myocardialInfarction)) {
    betaBlockerMedications(medications).forEach(medication => {
      if (medication.displayName === "Labetalol") {
        checkDosage(medication, result)
      }
    });
  }

}

function addMissingMedication(medications, kidneyIssuesPreferAceArb, tooHighAcr, myocardialInfarction, requiresAceOrArb, result) {
  const okForNonAceArb = nonAceOrArbMedicationIsOk(medications, tooHighAcr, myocardialInfarction);
  //console.log("Took me here", okForNonAceArb)
  //if (!kidneyIssuesPreferAceArb && !requiresAceOrArb && tzMedications(medications).length === 0) {
  if (okForNonAceArb && tzMedications(medications).length === 0) {
    result.recommendations.push(TZRecommendation());
    result.followups.push("Follow-up labs are needed within 2 weeks if adding chlorthalidone or hydrochlorothiazide.")
  //} else if (!requiresAceOrArb && ccbMedications(medications).length === 0 && !kidneyIssuesPreferAceArb && !myocardialInfarction) {
  } else if (okForNonAceArb && ccbMedications(medications).length === 0) {
    result.recommendations.push(CCBRecommendation());
  } else if ((aceMedications(medications).length === 0) && (arbMedications(medications).length === 0)) {
    result.recommendations.push(ACERecommendation());
  } 
}

function ccbMedications(medications) {
  return medications.filter(med => med.type === "CCB");
}

function tzMedications(medications) {
  return medications.filter(med => med.type === "Thiazide Diuretic");
}

function aceMedications(medications) {
  return medications.filter(med => med.type === "ACE inhibitor");
}

function arbMedications(medications) {
  return medications.filter(med => med.type === "ARB");
}

function onAceOrArb(medications) {
  let foundAceOrArb = false;

  medications.forEach(med => {
    if (med.type === 'ARB' || med.type === 'ACE inhibitor') {
      foundAceOrArb = true;
    } else if (med.medications) {
      med.medications.forEach(comboMed => {
        if (comboMed.type === 'ARB' || comboMed.type === 'ACE inhibitor') {
          foundAceOrArb = true;
        }
      })
    }
  });
  return foundAceOrArb;

}

function nonAceOrArbMedicationIsOk(medications, tooHighAcr, myocardialInfarction) {
  return onAceOrArb(medications) || (!tooHighAcr && !myocardialInfarction);
}


function betaBlockerMedications(medications) {
  return medications.filter(med => med.type === "Beta-blocker");
}

function medicationsWithType(medications, result) {
  console.log("In medicationsWithType")
  const relevantMedsTracker = {};

  // Go through the meds, removing ignored ones and overlaying any medication we find
  // with a similar one at a higher dosage
  medications.forEach(med => {

    const medDataAndType = medicationType(med);
    if (medDataAndType) {
      if (relevantMedsTracker.hasOwnProperty(medDataAndType.name)) {
        if ((medDataAndType.dosageAmount >= relevantMedsTracker[medDataAndType.name].dosageAmount) &&
            (medDataAndType.status === "active"))
        {
          //console.log("Replacing med with higher dosage")
          relevantMedsTracker[medDataAndType.name]['dosageAmount'] = medDataAndType.dosageAmount
          relevantMedsTracker[medDataAndType.name]['status'] = 'active'
        }
      } else {
        relevantMedsTracker[medDataAndType.name] = medDataAndType;
        if (MED_RECOS.dosages.hasOwnProperty(medDataAndType.name)) {
          relevantMedsTracker[medDataAndType.name]['optimalDosageAmount'] = MED_RECOS.dosages[medDataAndType.name].optimal_amount;
          relevantMedsTracker[medDataAndType.name]['maximumDosageAmount'] = MED_RECOS.dosages[medDataAndType.name].maximum_amount;
          relevantMedsTracker[medDataAndType.name]['optimalTimesDaily'] = MED_RECOS.dosages[medDataAndType.name].optimal_times_daily;
          relevantMedsTracker[medDataAndType.name]['increment'] = MED_RECOS.dosages[medDataAndType.name].increment;
        }
        console.log(relevantMedsTracker[medDataAndType.name]);
      }
    }
  });

  return Object.values(relevantMedsTracker);
}

function medPicker(medList, med) {  // reducer function
  if (med.recommended !== false) {
    if (med.initial_amount && med.unit) {
      return medList.concat({ name: med.name,
                              amount: med.initial_amount,
                              unit: med.unit,
                              numTablets: med.initial_tabs,
                              aglKey: getAGLKey(med.name, med.initial_amount, med.unit, med.initial_tabs),
                              preferred: med.preferred ? true : false,
                              details: med
      });
    } else
      return medList.concat({ name: med.name,
                              preferred: med.preferred ? true : false,        
                              details: med
      });
    }
  return medList;
}

function beginSPC() {
  var suboptions = []

  suboptions = MED_RECOS.spc['ace/tz'].reduce(medPicker, []);
  const opt1 = {main: "Use an ACE/HCTZ Single Pill combination:",
                verb: "add",
                medClasses: ["ace", "thiazide"],
                medName: "ACE/HCTZ SPC",
                showList: true,
                options: suboptions};

  suboptions = MED_RECOS.spc['ccb/ace'].reduce(medPicker, []);
  const opt2 = {main: "Use a CCB/ACE Single Pill combination:",
                verb: "add",
                medClasses: ["ace", "ccb"],
                medName: "CCB/ACE SPC",
                showList: true,
                options: suboptions};

  suboptions = MED_RECOS.spc['arb/tz'].reduce(medPicker, []);
  const opt3 = {main: "Use an ARB/TZ Single Pill combination:",
                verb: "add",
                medName: "ARB/TZ SPC",
                medClasses: ["arb", "thiazide"],
                showList: true,
                options: suboptions};

  const recs = [opt1, opt2, opt3]
  return recs
}

function LabetalolRecommendation() {
  return { main: "Add Labetalol 100 mg twice a day",
           verb: "add",
           medClasses: ["beta-blocker"],
           show: 1,
           medName: "Labetalol",
           aglKey: getAGLKey("labetalol", 100, "mg", 2),
           medAmount: 100,
           medUnit: "mg",
           options: [] }
}

function SpironolactoneRecommendation() {
  return { main: "Add Spironolactone 25 mg",
           verb: "add",
           medClasses: ["aldo-antagonist"],
           show: 1,
           medName: "Spironolactone",
           aglKey: getAGLKey("spironolactone", 25, "mg", 1),
           medAmount: 25,
           medUnit: "mg",
           options: [] }
}

function CCBRecommendation() {
  return {main: "Add one of the following CCB medications:",
          verb: "add",
          medName: "CCB",
          medClasses: ["ccb"],
          show: 1,
          options: convertMedClassToOptions('ccb')};
}

function TZRecommendation() {
  return {main: "Add one of the following Thiazide Diuretic medications:",
          verb: "add",
          medClasses: ["thiazide"],
          medName: "Thiazide",
          show: 1,
          options: convertMedClassToOptions('tz')};
}

function ACERecommendation() {
  return {main: "Add one of the following ACE medications:",
          verb: "add",
          medClasses: ["ace"],
          medName: "ACE",
          show: 1,
          options: convertMedClassToOptions('ace')};
}

function ARBRecommendation() {
  return {main: "Add one of the following ARB medications:",
          verb: "add",
          medClasses: ["arb"],
          medName: "ARB",
          show: 1,
          options: convertMedClassToOptions('arb')};
}

function AceTzSPCRecommendation(add=false) {
  const options = MED_RECOS.spc['ace/tz'].reduce(medPicker, []);
  const main_verb = add ? "Add" : "Switch to";
  const verb = add ? "add" : "switch to";

  return {main: `${main_verb} an ACE/HCTZ Single Pill combination:`,
          verb: verb,
          medClasses: ["ace", "thiazide"],
          medName: "ACE/HCTZ SPC",
          show: 1,
          options: options};
}

function CcbAceSPCRecommendation() {
 const options = MED_RECOS.spc['ccb/ace'].reduce(medPicker, []);
 return {main: "Switch to an CCB/ACE Single Pill combination:",
         verb: "switch to",
         medClasses: ["ace", "ccb"],
         medName: "CCB/ACE SPC",
         show: 1,
         options: options};
}

function ArbTzSPCRecommendation(add=false) {
  const options = MED_RECOS.spc['arb/tz'].reduce(medPicker, []);
  const main_verb = add ? "Add" : "Switch to";
  const verb = add ? "add" : "switch to";

  return {main: `${main_verb} an ARB/HCTZ Single Pill combination:`,
          verb: verb,
          medClasses: ["arb", "thiazide"],
          medName: "ARB/HCTZ SPC",
          show: 1,
          options: options};
}

function getHypertensionLevel(clinicMeasurements, homeStats, result) {
  /* 
  Via Lucia Pacca

  The code should identify patients with uncontrolled hypertension 
  as patients who have either two consecutive (including most recent) 
  clinic measurements OR average home BP (or both) out of goal.

  If the patient only has one out of goal BP among the most recent two, 
  the patient has “elevated blood pressure”.
  */
  const clinic_out_of_goal = !bpInGoal(clinicMeasurements, 0) && !bpInGoal(clinicMeasurements, 1) 
  const clinic_elevated = !bpInGoal(clinicMeasurements, 0) || !bpInGoal(clinicMeasurements, 1) 
  const home_out_of_goal = homeStats.systolicMean > HOME_SYSTOLIC_GOAL || homeStats.diastolicMean > HOME_DIASTOLIC_GOAL;

  if (clinicMeasurements.length > 0) {
    const notStr = bpInGoal(clinicMeasurements, 0) ? "" : "not "
    result.reasons.push(['BP Measurements', 
      `Most recent clinic BP measurement ${formatBP(clinicMeasurements, 0)} is ${notStr}in goal`,
      `Clinic BP Goal is ${SYSTOLIC_GOAL}/${DIASTOLIC_GOAL}`])
  }

  if (clinicMeasurements.length > 1) {
    const notStr = bpInGoal(clinicMeasurements, 1) ? "" : "not "
    result.reasons.push(['BP Measurements', 
      `Previous clinic BP measurement ${formatBP(clinicMeasurements, 1)} is ${notStr}in goal`,
      `Clinic BP Goal is ${SYSTOLIC_GOAL}/${DIASTOLIC_GOAL}`])
  }

  if (isNaN(homeStats.systolicMean) || isNaN(homeStats.diastolicMean)) {
    result.reasons.push(['BP Measurements', 
    `Mean home BP measurement is unknown`,
    `Home BP Goal is ${HOME_SYSTOLIC_GOAL}/${HOME_DIASTOLIC_GOAL}`])
  } else if (home_out_of_goal) {
    result.reasons.push(['BP Measurements', 
    `Mean home BP measurement ${formatHomeBP(homeStats.systolicMean, homeStats.diastolicMean)} is not in goal`,
    `Home BP Goal is ${HOME_SYSTOLIC_GOAL}/${HOME_DIASTOLIC_GOAL}`])
  } else {
    result.reasons.push(['BP Measurements', 
    `Mean home BP measurement ${formatHomeBP(homeStats.systolicMean, homeStats.diastolicMean)} is in goal`,
    `Home BP Goal is ${HOME_SYSTOLIC_GOAL}/${HOME_DIASTOLIC_GOAL}`])
  }

  if (clinic_out_of_goal || home_out_of_goal) {
    return UNCONTROLLED_HYPERTENSION;
  } else if (clinic_elevated) {
    return ELEVATED_BP
  } else {
    return OK;
  }
}

function bpInGoal(measurements, index, systolic_goal=SYSTOLIC_GOAL, diastolic_goal=DIASTOLIC_GOAL) {
  if (measurements === null || measurements.length === 0 || measurements.length <= index ) 
  {
    console.log("Got us some null-ish measurements")
    return true;
  }

  return measurements[index].systolic < systolic_goal && measurements[index].diastolic < diastolic_goal;
}

export { analyzePatient };
